
export class UserAuthentication {

    public UserName: string;
    public Password: string;
    public Subdomain: string;
}


export class CustomerDetails {
    public CustomerId: number;
    public Title: string;
    public FName: string;
    public LName: string;
    public DateOfBirth: string;
    public Phone: string;
    public Email: string;
    public AddressId: string;
    public ClientName: string;

}
export class AddUser {
    public Title: string;
    public FName: string;
    public LName: string;
    public Email: string;
    public UserTypeId: number;
    public AccountType: string;
    public CompanyId: number;
    public UserId: number;
    public Status: string;
    public IsDefaultClientAdmin: boolean;
}
export class AddClient {
    public ClientId: number;
    public ClientName: string;
    public ClientNo: number;
    public ClientSubDomain: string;
    public Title: string;
    public FName: string;
    public LName: string;
    public Email: string;
    public Status: string;
    public IsActive: string;

}
export class AddAccount {
    constructor(
        public code: string,
        public state: string
    ) { }
}
export class DecryptModel {
    public EncryptedState: string;
}
export class CustomerValidationModel {
    public KeyCode: string;
}

export class AccountDetails {
    public CashIn: string;
    public CashOut: string;
    public TotalBalance: string;
}

export class MenuList {
    public pageCode: string;
    public menuItem: string;

}

export class PricingList {
    public ClientId: number;
    public PartialPrice: number;
    public FullPrice: number;
    public BankPrice: number;
    public SMSPrice: number;

}

export class CreditList {
    public ClientId: number;
    public PartialCredit: number;
    public FullCredit: number;
    public BankCredit: number;
    public SMSCredit: number;
    public Email: string;
    public ClientFirstName: string;
}
export class BuyCredits {
    public ClientId: number;
    public PartialCredit: number;
    public FullCredit: number;
    public BankCredit: number;
    public SMSCredit: number;
    //public TotalAmount:number;
}

export enum ClientStatusEnum {
    active = 'Active',
    inactive = 'Inactive',
    delete = 'Delete',
}

export class CreditSummary {
    public partialCreditUsed: number;
    public partialCreditAvailable: number;
    public fullCreditUsed: number;
    public fullCreditAvailable: number;
    public smsCreditUsed:number;
    public smsCreditAvailable:number;
    public bankAccountCreditAvailable:number;
    public bankAccountCreditUsed:number;
}

export enum DashboardRoute {
    AgentDashboard = '/dashboard',
    ClientDashboard = '/company-dashboard',
    SuperDashboard = '/superadmin',
    systemDashboard = '/systemadmin-dashboard',
}

export interface UserPasswordDTO {
    UserName?: string;
    Password?: string;
}

export interface CustomerActivityModel {
    CustomerID: number;
    ActivityName: string;
}

export class FinalPrice {
    constructor(
        public finalFullPrice: number,
        public finalPartialPrice: number,
        public finalBankPrice: number,
        public finalSMSPrice: number,
        public TotalBalance: number,
        public TotalVAT: number,) { }
}


export class CardDetail {
    public CardNumber: number;
    public ExpireDate: string;
    public CVV: number;
    public NameOnCard: string;
    public CorrelationId: number;
    public Amount: number;
    public PartialSearch: number;
    public FullSearch: number;
    public AccountConnections: number;
    public NumberOfSms: number;
    public IsCardSave: boolean;
}

export class DownloadReport {
    public CustomerId: string;
    public StartDate: string;
    public EndDate: string;
}

export enum ResponseModel {
    Success = 1,
    Failed = -1,
    PartialRefresh = 2,

    // payment error status
    InvalidExpiryDate = 11,
    InvalidStartDate = 12,
    InvalidCVV = 13,
    InvalidCardNumber = 14,
    InvalidAmount = 15,
    CardNumberChanged = 16,
    CardExpired = 17,
    DeclinedDueToFunds = 18
}

export enum Card {
    NoCard = 0,
    MasterCard = 1,
    VisaCard = 2
}
export class Payment {
    constructor(
        public Loader: boolean,
        public PaymentResponse: boolean,
        public PaymentMessage: string,
        public IsDisable: boolean,
        public ColorStatus: boolean) { }
}

export interface AddressModel {
    id: string;
    address: string;
}

export interface UserAddress {
    Postcode: string;
    AddressId: string;
    NoOfAddress: number;
    FlatNumber: string;
    HouseName: string;
    HouseNumber: string;
    Street: string;
    Town: string;
    County: string;
    FullAddress: string;
}

export class UserAddressModel {
    constructor(
        public CustomerId: number,
        public Title: string,
        public FName: string,
        public LName: string,
        public DateOfBirth: string,
        public Phone: string,
        public Email: string,
        public ClientName: string,
        public UserAddresses: UserAddress[]
    ) { }
}

export interface CreditorDetails {
    CreditorName: string;
    Amount: number;
}

export interface CreditorsReport {
    CustomerId: number;
}

export enum PaymentStatus {
    InvalidExpiryDate = 'Invalid Expiry Date',
    InvalidStartDate = 'Invalid Start Date',
    InvalidCVV = 'Invalid CVV',
    InvalidCardNumber = 'Invalid Card Number',
    InvalidAmount = 'Invalid Amount',
    CardNumberChanged = 'Card Number Changed',
    CardExpired = 'Card Expired',
    DeclinedDueToFunds = 'Declined Due To Funds',
    Failed = 'Oops! Payment has failed, please retry.'
}

export class Creditmodal {
    public partialCredit: number=0;
    public fullCredit: number=0;
    public bankCredit: number=0;
    public smsCredit: number=0;
}

export interface UserStatusModel {
    UserId: number;
    Status: number;
}
export interface EmployeeStatusModel {
    EmpId: number;
    Status: number;
}

export enum StatusCode {
    Failed = -1,
    Success = 1,
    DuplicateEmail = 3
  }


