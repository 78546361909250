import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRights } from 'src/app/Extras/UserRights/userRights';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { AddUser, UserStatusModel } from '../../Models/dmsModel';

import { userTypeEnum } from '../../Extras/UserRights/userRights';
import { ActivatedRoute } from '@angular/router';
import { CryptographyService } from 'src/app/Services/cryptography.service';

declare let $: any;
export enum checkStatus {
  'One' = 'Active',
  'Zero' = 'Inactive'
}
export enum checkUserEnum {
  users = 'Users',
  systemAdmin = 'System Admin',
  systemAdminHeading = 'System Admins',
}
@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.css']
})
export class AdminUserListComponent implements OnInit {
  isSystemAdmin: boolean = false;
  userType: any;
  accountType: any;
  IsUserListShow: boolean = true;
  imgSrc = '../../../assets/ooki-assets/';
  UserForm: FormGroup;
  addUsers = new AddUser();
  editUsers = new AddUser();
  EmailMessage: boolean = false;
  requiredMsg: boolean = false;
  EmailId: any;
  FNErrmsg: boolean = false;
  LNErrmsg: boolean = false;
  RequiredDobMsg: boolean = false;
  submitted = false;
  ClientName: string;
  searchText = '';
  clientDropdownShow: boolean = false
  FNInvalidmsg: boolean = false;
  LNInvalidmsg: boolean = false; e;
  clientType: any;
  btnDisable: boolean = true;
  UserTypeId: number;
  IsDataFound: boolean;
  loader: boolean = true;
  IsSearchPageOpen: number = 0;
  public Status: any;
  item: number;  
  Type: string = '';
  superAdminBackBtn: any
  clientAdminBackBtn: any
  systemAdminBackBtn: any;
  ManageWhom: string;
  EmailCheck: boolean = false;
  IsShowClientName: boolean = false;
  state = false;
  //AgentSelected: any;
  PrefixManage: string = 'Manage ';
  DeletingWhom: string;
  IsSysAdminPopup: boolean = false;
  Title: string;
  FirstName: string;
  LastName: string;
  Email: string;
  UserType: string;
  UserId: number;
  CompanyId: any;
  isShow: boolean = false;
  accountDropdown: boolean = false;
  partialName: string;
  isEmailDisable: boolean = false;

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _dmsService: DmsasService,
    private userrights: UserRights,
    private route: ActivatedRoute, private _cryptoService: CryptographyService) {

    this.ClientName = sessionStorage.getItem('ClientName');
    let userTypeId = this.userrights.GetUserType();
    if (userTypeId == userTypeEnum.superAdmin) {
      this.superAdminBackBtn = true;
      this.IsShowClientName = true;
    }
    else if (userTypeId == userTypeEnum.systemAdmin) {
      this.systemAdminBackBtn = true;
      this.IsShowClientName = true;
    }
    else if (userTypeId == userTypeEnum.companyAdmin) {
      this.clientAdminBackBtn = true;
    }
  }

  ngOnInit(): void {
    this.item = 0;
    this.Type = '';
    this.route.params.subscribe((params: any) => {
      if (params['type'] != null) {
        this.Type = params['type'];
      }

      if (params['clientId'] != null) {        
        this.item = Number(this.decryptClientId(params['clientId'].toString()));
      }

      if (this.Type == "SysAdmin") {
        this.IsShowClientName = false;
        this.IsSysAdminPopup = true;
      }

      if (this.ManageWhom == this.PrefixManage + checkUserEnum.systemAdminHeading) {
        this.isSystemAdmin = true;
        this.IsSysAdminPopup = true;
      }
      else {
        this.IsSysAdminPopup = false;
      }
    });

    this.UserForm = this.formBuilder.group({
      Title: ['', Validators.required],
      FName: ['', Validators.required],
      LName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      UserTypeId: ['', Validators.required],
      CompanyId: [0],
    });

    this.getClient(this.item);
    //this.getClientName(Number(this.item));
    this.getUserType();
    this.Type == "SysAdmin" ? this.getSystemUser() : this.getUser();    
    
  }

  get f() {
    return this.UserForm.controls;
  }

  getUserType() {    
    this._dmsService.getUserType().subscribe((res: any) => {
      if (res.result != null) {        
        let userTypeId = this.userrights.GetUserType();
        if (userTypeId == userTypeEnum.superAdmin && this.Type == 'company') {
          this.accountType = res.result.filter(cc => cc.userTypeId == userTypeEnum.companyAdmin);          
        }
        else if (userTypeId == userTypeEnum.superAdmin && this.Type == 'SysAdmin') {
          this.accountType = res.result.filter(cc => cc.userTypeId == userTypeEnum.systemAdmin 
                                                  || cc.userTypeId == userTypeEnum.companyAdmin);          
        }
        else if (userTypeId == userTypeEnum.superAdmin) {
          this.accountType = res.result.filter(cc => cc.userTypeId !== userTypeEnum.superAdmin);
        }

        if (userTypeId == userTypeEnum.systemAdmin) {
          let item = res.result.filter(cc => cc.userTypeId !== userTypeEnum.systemAdmin);
          this.accountType = item.filter(cc => cc.userTypeId !== userTypeEnum.superAdmin);
        }

        if (userTypeId == userTypeEnum.companyAdmin) {
          this.accountType = res.result.filter(cc => cc.userTypeId == userTypeEnum.agent);
          this.addUsers.UserTypeId = userTypeEnum.companyAdmin;
        }
      }
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }

  getSystemUser() {    
    this.ManageWhom = this.PrefixManage + checkUserEnum.systemAdminHeading;
    this._dmsService.getUser().subscribe((res: any) => {      
      if (res?.result != null) {        
        this.userType = res.result.filter(userType => userType.userTypeId === userTypeEnum.systemAdmin 
                                                   || userType.userTypeId === userTypeEnum.companyAdmin);
        this.IsDataFound = this.userType.length > 0 ? false : true;
      }
      this.loader = false;
    });
  }

  getUser() {    
    this._dmsService.getUser().subscribe((res: any) => {
      if (res?.result != null) {
        this.ManageWhom = this.PrefixManage + checkUserEnum.users;
        this.userType = res.result;
        if (this.item > 0) {
          this.userType = this.userType.filter(userType => userType.companyId === Number(this.item));
          this.ManageWhom = this.ClientName != null ? `${checkUserEnum.users} for ${this.ClientName}` : `${checkUserEnum.users}`;
        }
        this.IsDataFound = this.userType.length > 0 ? false : true;
      }
      else {
        this.ManageWhom = checkUserEnum.users;
      }
      this.loader = false;
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }

  ViewUser(editdetailsmodal: any, item: any) {      
    let userId = item.userId;
    this.isEmailDisable = item.isPassEnable == 1 ? true : false;
    let data = this.userType.filter(cc => cc.userId == userId);
    this.requiredMsg = this.EmailMessage = false;
    if (data != undefined) {
      this.UserId = userId;
      this.editUsers = data[0];
      this.Title = data[0].title;
      this.FirstName = data[0].fName;
      this.LastName = data[0].lName;
      this.Email = data[0].email;
      this.UserType = data[0].userTypeName;
      this.ClientName = data[0].clientName;
    }
    this.modalService.open(editdetailsmodal, { windowClass: 'custom-class' });
  }

  open(content: any) {
    this.EmailCheck = false;
    this.clientDropdownShow = false;
    this.UserForm.reset();
    this.EmailId = '';
    this.requiredMsg = this.EmailMessage = false;
    this.UserForm.controls['Title'].setValue('');
    this.UserForm.controls['CompanyId'].setValue(0);
    
    this.modalService.open(content, 
      { windowClass: 'custom-class' }
    );    
    
    let userTypeId = this.userrights.GetUserType();    
    if (userTypeId == userTypeEnum.companyAdmin || this.ManageWhom == this.PrefixManage + checkUserEnum.systemAdminHeading) {
      this.accountDropdown = false;
      this.clientDropdownShow = false;
      this.btnDisable = false;
      //this.UserForm.get('UserTypeId').disable();
      if (userTypeId == userTypeEnum.companyAdmin) {
        //this.AgentSelected = this.accountType[0];
        this.UserForm.controls['UserTypeId'].setValue(this.accountType[0].userTypeId);
      }
      else {        
        //this.AgentSelected = this.accountType[2];
        this.UserForm.controls['UserTypeId'].setValue(0);
      }
    }
    else {
      this.accountDropdown = true;
      this.UserForm.controls['UserTypeId'].setValue('');
    }
  }

  EditUser(editdetailsmodal: any, item: any, id: any = null) {    
    let userId = item != undefined ? item.userId : 0;
    if (id != null && id != "") {
      userId = id;
    }
    else {
      this.isEmailDisable = item.isPassEnable == 1 ? true : false;
    }

    this.clientDropdownShow = false;
    this.EmailCheck = false;
    this.isShow = true;
    let data = this.userType.filter(cc => cc.userId == userId);
    this.EmailId = data[0].email;
    this.requiredMsg = this.EmailMessage = false;
    
    if (data != undefined) {
      this.UserId = userId;
      this.editUsers = data[0];
      this.Title = data[0].title;
      this.FirstName = data[0].fName;
      this.LastName = data[0].lName;
      this.Email = data[0].email;
      this.UserTypeId = data[0].userTypeId;
      this.UserType = data[0].userTypeName;
      this.CompanyId = data[0].companyId;
      this.Status = data[0].status;
            
      if (this.Status == checkStatus.Zero) {
        this.Status = '0';
      }
      else if (this.Status == checkStatus.One) {
        this.Status = '1';
      }
      
      this.UserForm.controls['Title'].setValue(this.Title);
      this.UserForm.controls['FName'].setValue(this.FirstName);
      this.UserForm.controls['LName'].setValue(this.LastName);
      this.UserForm.controls['Email'].setValue(this.Email);
      this.UserForm.controls['UserTypeId'].setValue(this.UserTypeId);
      this.UserForm.controls['CompanyId'].setValue(this.CompanyId);

      if(this.UserTypeId == userTypeEnum.companyAdmin){
        this.clientDropdownShow = true;
        this.UserForm.get('CompanyId').disable();
      }
    }
    this.modalService.open(editdetailsmodal, { windowClass: 'custom-class' });
  }

  DeleteActiveUser() {
    this._dmsService.DeleteUsers(this.UserId).subscribe((res => {
      if (this.ManageWhom != this.PrefixManage + checkUserEnum.systemAdminHeading) {
        this.getUser();
      }
      else {
        this.getSystemUser();
      }
    }));
    $('#userdelete').modal('hide');
  }

  openDeleteModal(userId: any) {
    let data = this.userType.filter(cc => cc.userId == userId);
    this.UserId = userId;
    this.FirstName = data[0].fName;
    this.LastName = data[0].lName;
    this.Email = data[0].email;
    this.DeletingWhom = data[0].userTypeName;
    $('#userdelete').modal('show');
  }

  closeModal() {
    $('#userdelete').modal('hide');
  }

  ChangeStatus(status: number, userId: number) {

    const userStatus: UserStatusModel = {
      UserId: userId,
      Status: status
    };    

    this._dmsService.UpdateUserStatus(userStatus).subscribe((res => {
      if (this.ManageWhom != this.PrefixManage + checkUserEnum.systemAdminHeading) {
        this.getUser();
      }
      else {
        this.getSystemUser();
      }
    }))
  }

  UpdateUser(userId: any) {
    this.submitted = true;
    if (this.UserForm.invalid) {
      return;
    }
    else {
      this.editUsers.UserId = userId;
      this.editUsers.Status = this.Status;
      this.editUsers.Title = this.UserForm.controls["Title"].value;
      this.editUsers.FName = this.UserForm.controls["FName"].value;
      this.editUsers.LName = this.UserForm.controls["LName"].value;
      this.editUsers.Email = this.UserForm.controls["Email"].value;
      this.editUsers.AccountType = this.UserForm.controls["UserTypeId"].value;
      this._dmsService.UpdateUsers(this.editUsers).subscribe((res: any) => {
        if (res?.result?.userStatus == 1) {
          this.EmailCheck = true;
        }
        else {
          this.modalService.dismissAll();
          if (this.ManageWhom != this.PrefixManage + checkUserEnum.systemAdminHeading) {
            this.getUser();
          }
          else {
            this.getSystemUser();
          }
        }
      });
    }
  }

  keyDownFunction(event: any, nametype) {
    event.target.value = (event.target.value.replace(/\s\s+/g, ' ')).trim();
    let Name = event.target.value;
    if (Name != '') {
      this.partialName = Name.split(' ');
    }
    if (nametype == 'fname') {
      if (Name == '') {
        this.UserForm.controls['FName'].setErrors({ 'incorrect': true });
        this.FNErrmsg = true
      }
      else {
        for (let value of this.partialName) {
          if (value.length < 2) {
            this.UserForm.controls['FName'].setErrors({ 'incorrect': true });
            this.FNInvalidmsg = true;
          }
        }
      }
    }
  }

  onSubmit() {
    
    this.submitted = true;
    if (this.UserForm.invalid) {
      return;
    }
    this.addUsers = this.UserForm.getRawValue();
    if (this.addUsers.CompanyId == 0) {
      this.addUsers.CompanyId = Number(sessionStorage.getItem('ClientId'));
    }
        
    let result = this.clientType.filter(r => (r.userCompanyId > 0) && (r.email == this.addUsers.Email))[0];
    if (result != undefined && result.email) {
      this.EmailCheck = true;
      return;
    }

    this._dmsService.AddUsers(this.addUsers).subscribe((res: any) => {
      this.EmailCheck = true;
      if (res?.statusCode == 1 && res?.status == 'NewUser') {
        this.EmailCheck = false;
        this.modalService.dismissAll();
        this.Type == "SysAdmin" ? this.getSystemUser() : this.getUser();
      }
    });
  }

  getClient(companyId: number) {    
    this._dmsService.getClient().subscribe((res: any) => {
      if (res.result != null) {
        let companyList = this.clientType = res.result;
        if (companyList.length > 0) {
          let companyselected: any = companyList.filter(cl => cl.companyId === companyId);
          if (companyselected != null && companyselected.length > 0) {
            this.ClientName = companyselected[0].name;
            this.ManageWhom = `${checkUserEnum.users} for ${this.ClientName}`;
          }
        }
      }
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }  

  openClientDropdown(userTypeId: any) {    
    if (userTypeId == 1 || userTypeId == 2) {
      this.clientDropdownShow = true;
      if (this.item === undefined || this.item == null) {
        this.UserForm.controls['CompanyId'].setValue(0);
      }
      else {
        this.UserForm.controls['CompanyId'].setValue(Number(this.item));
      }
      this.btnDisable = false;      
    }
    else {
      this.clientDropdownShow = false;
      this.btnDisable = false;
    }
  }

  btnEnable() {
    this.isShow = false;
    this.btnDisable = false;
    this.EmailCheck = false;
  }

  searchClicked(event) {
    this.IsSearchPageOpen = event;
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  

  decryptClientId(clientId: string): string {
    return this._cryptoService.Decrypt_Client_Data(clientId);
  }

}
