import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  env: string;
  constructor(private router: Router) {
    this.env = environment.name;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        if (event.body) {
          if ((event.body.status == 'Failed' || event.body.statusCode == -1) && (this.env == 'Dev' ||  this.env == 'Local')) {
            var c = document.createElement("div");
            c.setAttribute("style", "height: 60px;width: 440px;background-color: #c5e2ed;color: red;z-index: 9999;position: absolute;text-align: center;padding-top: 14px;margin-top: 518px;");
            c.setAttribute("id", "error");
            c.innerHTML = "Something went wrong, please try again or contact support!";
            var d = document.getElementById("gerror");
            d.append(c);
            setTimeout(() => {
              document.getElementById("error").remove();
            }, 4000);
          }
        }
      }
    }));
  };


}
