import { Component, OnDestroy, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-inactivity-timer',
  templateUrl: './inactivity-timer.component.html',
  styleUrls: ['./inactivity-timer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbModalConfig, NgbModal]
})
export class InactivityTimerComponent implements OnDestroy, AfterViewChecked, OnChanges {
  imgSrc = environment.imgBasURL;
  minutesDisplay = 0;
  secondsDisplay = 0;

  endTime: number;
  minutes: any;
  second: number;

  timerSeconds: number;
  minInSeconds: number;
  noMinutes: number;
  sessionConstant: string;
  stillHere: string = null;
  stillHereCondition: string = '';
  Onedigit: boolean = false;
  RefreshTokenMinuteBeforeExpire: any;
  url: any;
  CurrentTime: any;
  UserDetailList: any;
  timeLeft: number;
  interval;

  @ViewChild('TimerOpen') TimerOpen: ElementRef;
  @Input() IsTimerOpenPopup: boolean;
  @Output() SetTimerOpenPopup: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private cfr: ChangeDetectorRef,
    private bnIdle: BnNgIdleService,
  ) {
    this.url = environment.configUrl;
    this.timerSeconds = this.url['timerSeconds'];
    this.minInSeconds = this.url['minuteInSeconds'];
    this.noMinutes = this.url['noMinutes'];
    this.sessionConstant = this.url['sessionConstant'];
    this.stillHereCondition = this.url['stillHereCondition']; //"stillHere";
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    let isExist: boolean = changes.IsTimerOpenPopup.currentValue; 
    console.log("OnChanges: " + isExist);   
    if (isExist && sessionStorage.getItem('token')) {
      console.log("OnChanges: Opened");   
      this.TimerOpen.nativeElement.click();
      this.interval = this.startTimer();
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      
    }
    
  }

  ngAfterViewChecked() {
    this.cfr.detectChanges();
  }

  open(content: any) {
    this.modalService.open(content,
      { windowClass: 'n-class' }
    );
  }

  startTimer() {
    this.timeLeft = this.timerSeconds;
    if (this.timeLeft == undefined) {
      return;
    }

    return setInterval(() => {

      if (this.timeLeft < 0 && sessionStorage.getItem('token')) {
        console.log("Return : " + this.timeLeft)
        clearInterval(this.interval);
        delete this.timeLeft;
        localStorage.clear();
        sessionStorage.clear();
        this.SetTimerOpenPopup.emit(false);
        sessionStorage.setItem('sessionTimeout', this.sessionConstant);
        
        this.modalService.dismissAll();  
        this.router.navigate(['/login']);
        return;
      }
      
      if (this.timeLeft >= this.minInSeconds) {
        this.minutes = (this.timeLeft / this.minInSeconds).toString().split('.');
        this.minutes = +this.minutes[0];
        this.second = this.timeLeft % this.minInSeconds;        
        let secondlength = this.second.toString().length;
        if (secondlength == 1) {
          this.Onedigit = true;
        } else {
          this.Onedigit = false;
        }
      }
      else {
        this.minutes = this.noMinutes;
        this.second = this.timeLeft;
        if (this.second.toString().length == 1) {
          this.Onedigit = true;
        } else {
          this.Onedigit = false;
        }
      }

      this.timeLeft = this.timeLeft - 1;
    }, 1000);
  }

  Refreshtoken() {
    console.log("stillHere : " + this.timeLeft)
    this.minutes = this.second = 0;
    clearInterval(this.interval);
    delete this.timeLeft;
    delete this.interval;    
    this.SetTimerOpenPopup.emit(false);
    this.bnIdle.resetTimer();
    this.modalService.dismissAll();
  }

}
