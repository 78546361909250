import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditSummary } from '../../Models/dmsModel';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { userTypeEnum } from 'src/app/Extras/UserRights/userRights';
@Component({
  selector: 'app-agentdashboard',
  templateUrl: './agentdashboard.component.html',
  styleUrls: ['./agentdashboard.component.css']
})

export class AgentdashboardComponent implements OnInit {
  IsSearchPageOpen: number = 0;
  CreditSummary = new CreditSummary();
  loader: boolean = false;
  IsNoCredits: boolean = false;
  Ctype: string = "";
  UserType;
  companyId: number;
  IsWageAdvanceExists: boolean = false;

  constructor(private _dmsService: DmsasService, private router: Router, private route: ActivatedRoute, private modalService: NgbModal) {

    this.UserType = sessionStorage.getItem('type');
    if (this.UserType == userTypeEnum.companyAdmin) {
      this.companyId = Number(sessionStorage.getItem('ClientId'));
    }

  }

  ngOnInit(): void {
    //this.GetCreditSummary();
    this.getCompanyDetails(this.companyId);
  }

  getCompanyDetails(companyId: number) {
    this._dmsService.getCompanyDetails(companyId).subscribe((res: any) => {
      if (res.statusCode == 1 && res.result != null) {
        let companyDetail = res.result;
        this.companyId = Number(companyDetail.companyId);
        this.IsWageAdvanceExists = this.GetWageAdvanceExists(companyDetail.accessibleFeatures);        
      }

    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }

  GetWageAdvanceExists(accessibleFeature: any): boolean {
    let isWageAdvanceExists: boolean = false;
    if (accessibleFeature) {
      const index = accessibleFeature.findIndex(object => object.featureId == 1);
      if (index !== -1) {
        isWageAdvanceExists = true;
      }
    }
    return isWageAdvanceExists;
  }

  searchClicked(event) {
    this.IsSearchPageOpen = event;
  }

  // GetCreditSummary() {
  //   this._dmsService.getCreditSummary(this.clientId).subscribe((res: any) => {
  //     if (res.result != null) {
  //       this.loader = false;
  //       this.CreditSummary = res.result;
  //       if (this.CreditSummary.fullCreditAvailable < 1 && this.IsNoCredits) {
  //         this.modalService.open(this.buycreditmodal,
  //           { backdrop: 'static', keyboard: false }
  //         );
  //         let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
  //         window.history.pushState({ path: newurl }, '', newurl);
  //       }
  //       else {
  //         this.modalService.dismissAll();
  //         let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
  //         window.history.pushState({ path: newurl }, '', newurl);
  //       }
  //     }
  //     else {
  //       this.loader = false;
  //       this.CreditSummary.partialCreditUsed = 0;
  //       this.CreditSummary.partialCreditAvailable = 0;
  //       this.CreditSummary.fullCreditUsed = 0;
  //       this.CreditSummary.fullCreditAvailable = 0;
  //       this.CreditSummary.smsCreditUsed=0;
  //       this.CreditSummary.smsCreditAvailable=0;
  //       this.CreditSummary.bankAccountCreditAvailable=0;
  //       this.CreditSummary.bankAccountCreditUsed=0;
  //       if (this.IsNoCredits) {
  //         this.modalService.open(this.buycreditmodal, { backdrop: 'static', keyboard: false });
  //       }
  //       let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
  //       window.history.pushState({ path: newurl }, '', newurl);
  //     }
  //   }, (err: HttpErrorResponse) => {
  //     console.log('Catch exception: ' + err);
  //   });
  // }

  BuyCredits() {
    // this.modalService.dismissAll();
    // this.router.navigateByUrl('/buy-credits', { state: { clientId: this.clientId } });
  }

}
