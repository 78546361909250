<app-nav-superadmin (NewSearchClick)="searchClicked($event)"></app-nav-superadmin>
<div class="body-bg container-fluid">
  <div class="row">
    <div class="col-12">
      <div *ngIf="clientAdminBackBtn" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/company-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp; Manage
        Users</div>
      <div *ngIf="systemAdminBackBtn" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/systemadmin-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp;
        Manage Administrators</div>
      <div *ngIf="superAdminBackBtn" class="recent-heading"><em routerLinkActive="active" [routerLink]="['/superadmin']"
          style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp; Manage Administrators</div>
      <div class="table-box">
        <div class="input-div d-flex">
          <input *ngIf="!isSystemAdmin" type="text"
            style="background: url(../../../../assets/images/Searches_icon.svg) no-repeat 1%;" class="txt form-control"
            placeholder="Search Administrators" width="100%" [(ngModel)]="searchText" />
          <input *ngIf="isSystemAdmin" type="text" class="txt form-control" placeholder="Search Administrators"
            width="100%" [(ngModel)]="searchText" />
          <button *ngIf="!IsSysAdminPopup" class="btn btn-submit" type="button" (click)="open(adddetailsmodal)">Add
            Administrator</button>
          <button *ngIf="IsSysAdminPopup" class="btn btn-submit btn-sy" type="button"
            (click)="open(adddetailsmodal)">Add System Admin</button>
        </div>
        <div class="table-div">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="padding-left-0">Name</th>
                <th scope="col">Company Details</th>
                <th scope="col">Account Type</th>
                <th scope="col">Status</th>
                <th scope="col">Created on</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngIf="!IsDataFound">
              <tr *ngFor="let item of (userType | filterdata:searchText)">
                <div *ngIf="item === -1; then NoFiltered"></div>
                <td *ngIf="item !== -1" class="padding-left-0"
                  [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'">
                  <span *ngIf="item.title != 'Other'"> {{item.title}}</span> {{item.fName}} {{item.lName}}<br><strong
                    class="">{{item.email}} </strong>
                </td>
                <td *ngIf="item !== -1" class="td-child"
                  [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'">
                  <span>{{item.clientName || '-'}}<br><strong class="">{{item.clientNumber}}</strong></span>
                </td>
                <td *ngIf="item !== -1" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'">
                  {{item.userTypeName}}</td>

                <td *ngIf="item !== -1" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'">
                  {{item.status}}</td>

                <td *ngIf="item !== -1" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'">
                  <span>{{item.createdOn}}</span><br><span class="by-name">{{item.createdBy == '' ? '' : 'by ' +
                    item.createdBy}}</span>
                </td>

                <td *ngIf="item !== -1" class="td-childo padding-left-0 text-righttt">
                  <div class="dropdown dropleft">
                    <a class="btn-new-b dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="assets\dsms-assets\dot_n.svg" alt="image not found" />
                    </a>
                    <div class="dropdown-menu-new dropdown-menu" id="padding-menu" aria-labelledby="dropdownMenuButton">
                      <div class="dropdown-item" (click)="ViewUser(viewdetailsmodal,item)">View</div>
                      <div class="dropdown-item" (click)="EditUser(editdetailsmodal,item,'')">Edit</div>
                      <div class="dropdown-item" *ngIf="item.status == 'Active' && item.isPassEnable==1"
                        (click)="ChangeStatus(0, item.userId)">Deactivate</div>
                      <div class="dropdown-item" *ngIf="item.status == 'Inactive' && item.isPassEnable==1"
                        (click)="ChangeStatus(1, item.userId)">Activate</div>
                      <div class="dropdown-item" data-toggle="modal" data-target="#userdelete"
                        (click)="openDeleteModal(item.userId)">Delete</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <!--loader-->
            <tbody *ngIf="IsDataFound">
              <tr>
                <td colspan="5">
                  <div class="texttable">No records found</div>
                </td>
              </tr>
            </tbody>
            <ng-template #NoFiltered>
              <td colspan="5">
                <div class="texttable">No records found</div>
              </td>
            </ng-template>
            <tbody *ngIf="loader">
              <tr>
                <td colspan="5">
                  <div id="loader">
                    <div class="text-center">
                      <div class="spinner-border" role="status"></div>
                      <div class="textloader">Please wait while we are gathering data …</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <!--  -->
          </table>
        </div>
      </div>
      <!-- Add User Modal -->
      <ng-template #adddetailsmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div *ngIf="!IsSysAdminPopup" class="modal-title" id="modal-basic-title">Add New Administrator</div>
            <div *ngIf="IsSysAdminPopup" class="modal-title" id="modal-basic-title">Add the New System Admin</div>
            <button type="button" class="close close-btn" aria-label="Close"
              (click)="modal.dismiss('Cross click');UserForm.reset()">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="UserForm">
              <div class="d-flex">
                <div class="form-group">
                  <select class="form-control" id="tittle-css" name="title" formControlName="Title"
                    [ngClass]="{ 'is-invalid': f.Title.touched && f.Title.value =='' }">
                    <option value="" [disabled]="true" selected hidden>Title <sup>*</sup></option>
                    <option value="Mr"> Mr</option>
                    <option value="Mrs"> Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Sir">Sir</option>
                    <option value="Dr">Dr</option>
                    <option value="Rev">Rev</option>
                    <option value="Master">Master</option>
                    <option value="Professor">Professor</option>
                    <option value="Other">Other</option>
                  </select>
                  <div *ngIf="f.Title.touched && f.Title.value ==''" class="invalid-feedback"> Please select title</div>
                </div>
                <div class="form-group">
                  <input type="text" id="name-first" placeholder="First Name*" value="FName"
                    class="form-control margin-left-20" autocomplete="FName-off"
                    (focusout)="keyDownFunction($event,'fname')"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39 || event.charCode == 45"
                    formControlName="FName" [ngClass]="{ 'is-invalid': f.FName.touched && f.FName.errors }"
                    minlength="2" maxlength="30" (focus)="FNErrmsg=false" (focus)="FNInvalidmsg=false" />
                  <div *ngIf="f.FName.errors" class="invalid-feedback newmargin">
                    <div *ngIf="f.FName.errors.required || FNErrmsg">Please enter first name</div>
                    <div *ngIf="(f.FName.errors.minlength  || FNInvalidmsg) && !FNErrmsg">Please enter a valid first
                      name</div>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="form-group">
                  <input type="text" placeholder="Last Name*" id="input-box" class="form-control" required
                    formControlName="LName" autocomplete="LName-off" (focusout)="keyDownFunction($event,'lname')"
                    [ngClass]="{ 'is-invalid': f.LName.touched && f.LName.errors }" minlength="2" maxlength="30"
                    (focus)="LNErrmsg=false" (focus)="LNInvalidmsg=false"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39  || event.charCode == 45">
                  <div *ngIf="f.LName.errors" class="invalid-feedback">
                    <div *ngIf="f.LName.errors.required || LNErrmsg">Please enter last name</div>
                    <div *ngIf="(f.LName.errors.minlength || LNInvalidmsg) !LNErrmsg">Please enter a valid last name
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group">
                  <input type="email" placeholder="Email*" id="input-box" formControlName="Email" [(ngModel)]="EmailId"
                    class="form-control" (focus)="EmailCheck=false" autocomplete="off"
                    [ngClass]="{ 'is-invalid': f.Email.touched && f.Email.errors || EmailCheck}" />
                  <div *ngIf="f.Email.touched && f.Email.errors" class="invalid-feedback">
                    <div *ngIf="f.Email.errors.required">Email is required</div>
                    <div *ngIf="f.Email.errors.email ||(!f.Email.errors.required && f.Email.errors.email==null)">Please
                      enter a valid email address</div>
                  </div>
                  <div class="invalid-feedback">
                    <div *ngIf="EmailCheck">This email address is already in use</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <select class="form-control" id="input-box" formControlName="UserTypeId"
                    (change)="openClientDropdown(f.UserTypeId.value)"
                    [ngClass]="{ 'is-invalid':f.UserTypeId.value =='' && f.UserTypeId.touched }">
                    <option value="0" selected>Account Type <sup>*</sup></option>
                    <option *ngFor="let item of accountType" [ngValue]="item.userTypeId">{{item.userTypeName}}</option>
                  </select>
                  <div class="invalid-feedback">
                    <div *ngIf="f.UserTypeId.value =='' && f.UserTypeId.touched">Account type is required</div>
                  </div>
                </div>
              </div>
              <!-- <ng-template #disableDropdown>
                <div class="form-group">
                  <select class="form-control" id="input-box" formControlName="UserTypeId"
                    *ngIf="AgentSelected != null && AgentSelected != ''">
                    <option [ngValue]="AgentSelected?.userTypeId" selected>{{AgentSelected?.userTypeName}}</option>
                  </select>
                </div>
              </ng-template> -->
              <div *ngIf="clientDropdownShow && this.Type != 'company'" class="form-group">
                <select class="form-control" id="input-box" formControlName="CompanyId" (change)="btnEnable()"
                  [ngClass]="{ 'is-invalid':f.CompanyId.value == 0 && f.CompanyId.touched }">
                  <option value="0" [disabled]="true" selected hidden>Select Company Name <sup>*</sup></option>
                  <option *ngFor="let item of clientType" [selected]="item.companyId == this.f.CompanyId.value"
                    [ngValue]="item.companyId">{{item.name}}</option>
                </select>
                <div class="invalid-feedback">
                  <div *ngIf="f.CompanyId.value == 0 && f.CompanyId.touched">Company name is required</div>
                </div>
              </div>
            </form>
            <div class="btn-box">
              <button *ngIf="!IsSysAdminPopup" class="btn btn-submit-modal" type="submit"
                [disabled]="UserForm.invalid || btnDisable || EmailMessage" (click)="onSubmit()">Add User</button>
              <button *ngIf="IsSysAdminPopup" class="btn btn-submit-modal" type="submit"
                [disabled]="UserForm.invalid || btnDisable || EmailMessage" (click)="onSubmit()">Add System
                Admin</button>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Edit User Modal -->
      <ng-template #editdetailsmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div *ngIf="!IsSysAdminPopup" class="modal-title" id="modal-basic-title">Edit User Details</div>
            <div *ngIf="IsSysAdminPopup" class="modal-title" id="modal-basic-title">Edit System Admin Details</div>
            <button type="button" class="close close-btn" aria-label="Close"
              (click)="modal.dismiss('Cross click');UserForm.reset()">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="UserForm">
              <div class="d-flex">
                <div class="form-group">
                  <select class="form-control" id="tittle-css" (change)="btnEnable()" name="title"
                    formControlName="Title" [ngClass]="{ 'is-invalid': f.Title.touched && f.Title.value =='' }">
                    <option value="" [disabled]="true" selected hidden>Title <sup>*</sup></option>
                    <option value="Mr"> Mr</option>
                    <option value="Mrs"> Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Sir">Sir</option>
                    <option value="Dr">Dr</option>
                    <option value="Rev">Rev</option>
                    <option value="Master">Master</option>
                    <option value="Professor">Professor</option>
                    <option value="Other">Other</option>
                  </select>
                  <div *ngIf="f.Title.touched && f.Title.value ==''" class="invalid-feedback"> Please select title</div>
                </div>
                <div class="form-group">
                  <input type="text" id="name-first" placeholder="First Name*" value="FName"
                    class="form-control margin-left-20" autocomplete="FName-off"
                    (focusout)="keyDownFunction($event,'fname')" (keydown)="btnEnable()"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39 || event.charCode == 45"
                    formControlName="FName" [ngClass]="{ 'is-invalid': f.FName.touched && f.FName.errors }"
                    minlength="2" maxlength="30" (focus)="FNErrmsg=false" (focus)="FNInvalidmsg=false" />
                  <div *ngIf="f.FName.errors" class="invalid-feedback newmargin">
                    <div *ngIf="f.FName.errors.required || FNErrmsg">Please enter first name</div>
                    <div *ngIf="(f.FName.errors.minlength  || FNInvalidmsg) && !FNErrmsg">Please enter a valid first
                      name</div>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="form-group">
                  <input type="text" placeholder="Last Name*" id="input-box" class="form-control" required
                    formControlName="LName" autocomplete="LName-off" (focusout)="keyDownFunction($event,'lname')"
                    (keydown)="btnEnable()" [ngClass]="{ 'is-invalid': f.LName.touched && f.LName.errors }"
                    minlength="2" maxlength="30" (focus)="LNErrmsg=false" (focus)="LNInvalidmsg=false"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39  || event.charCode == 45">
                  <div *ngIf="f.LName.errors" class="invalid-feedback">
                    <div *ngIf="f.LName.errors.required || LNErrmsg">Please enter last name</div>
                    <div *ngIf="(f.LName.errors.minlength || LNInvalidmsg) !LNErrmsg">Please enter a valid last name
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group">
                  <input type="email" [attr.disabled]="isEmailDisable==true?true:null" placeholder="Email*"
                    id="input-box" (keydown)="btnEnable();" formControlName="Email" [(ngModel)]="EmailId"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': (f.Email.touched && f.Email.errors) || EmailCheck }" />
                  <div *ngIf="f.Email.touched && f.Email.errors" class="invalid-feedback" data=f.Email>
                    <div *ngIf="f.Email.errors.required">Email is required</div>
                    <div *ngIf="f.Email.errors.email ||(!f.Email.errors.required && f.Email.errors.email==null)">Please
                      enter a valid email address</div>
                  </div>
                  <div *ngIf="EmailCheck" class="invalid-feedback">
                    <div>This email address is already in use</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <select class="form-control" id="input-box" disabled>
                  <option [selected]="this.f.UserTypeId.value">{{UserType}}</option>
                </select>
              </div>
              <div *ngIf="clientDropdownShow" class="form-group">
                <select class="form-control" id="input-box" formControlName="CompanyId">
                  <option *ngFor="let item of clientType" [selected]="item.companyId == this.f.CompanyId.value"
                    [ngValue]="item.companyId">{{item.name}}</option>
                </select>
                <div class="invalid-feedback">
                  <div *ngIf="f.CompanyId.value == 0 && f.CompanyId.touched">Company name is required</div>
                </div>
              </div>
            </form>
            <div class="btn-box">
              <button class="btn btn-submit-modal" type="submit" [disabled]="isShow || UserForm.invalid || EmailMessage"
                (click)="UpdateUser(UserId);" isShow=true;>Update Details</button>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- View User Modal -->
      <ng-template #viewdetailsmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div *ngIf="!IsSysAdminPopup" class="modal-title" id="modal-basic-title">View User Details</div>
            <div *ngIf="IsSysAdminPopup" class="modal-title" id="modal-basic-title">View System Admin Details</div>
            <button type="button" class="close close-btn" aria-label="Close"
              (click)="modal.dismiss('Cross click');UserForm.reset()">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="UserForm">
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Title</div>
                <div class="text-value">{{Title}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">First Name</div>
                <div class="text-value">{{FirstName}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Last Name</div>
                <div class="text-value">{{LastName}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Email</div>
                <div class="text-value">{{Email}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Accouny Type</div>
                <div class="text-value">{{UserType}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Company Name</div>
                <div class="text-value">{{ClientName}}</div>
              </div>
            </form>
            <div class="btn-box">
              <button class="btn btn-submit-modal" type="submit"
                (click)="EditUser(editdetailsmodal,'',UserId);modal.dismiss('Cross click')">Edit Details</button>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="modal fade" id="userdelete" tabindex="-1" role="dialog" aria-labelledby="insidemodalTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" id="modal-inside">
            <div class="modal-body">
              <div class="text-center">
                <img class="img-fluid" src="../../../assets/images/delete-icon.svg" alt="image">
              </div>
              <div class="delete-header">Delete {{Email}}?</div>
              <div class="text-delete">Deleting {{DeletingWhom}} <strong>{{FirstName}} {{LastName}}</strong> will remove
                all data related to this user from the system.</div>
              <div class="text-delete marginx">Still want to proceed?</div>
              <div class="d-flex" id="margin-y">
                <button type="button" class="btn btn-no" (click)="closeModal()" data-dismiss="modal">No</button>
                <button type="button" class="btn btn-submit btn0-w" (click)="DeleteActiveUser()">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>