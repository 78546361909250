<app-nav-superadmin></app-nav-superadmin>
<div class="body-bg container-fluid">
    <div class="row">
    <div class="col-12">
    <div class="recent-heading"><em class="fas fa-arrow-left"></em> &nbsp; Invoices for Debts Made Simple</div>
    <div class="table-box">
    <div class="input-div d-flex justify-content-between">
 </div>
    <div class="table-div">
    <table class="table">
    <thead>
    <tr>
    <th scope="col">Purchase Date</th>
    <th scope="col">Invoice Description</th>
    <th scope="col">Amount</th>
    <th scope="col">Status</th>
    <th scope="col">Download</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td class="td-child">20/08/1980</td>
    <td class="td-child">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
    <td class="td-child">Boots the Chemicals</td>
    <td class="td-child">Health & Fitness</td>
    <td class="td-child text-center"><img src="../../../assets/images/CTA_pdf.svg" class="img-fluid" alt="image not found"></td>
    </tr>
    
    
     </tbody></table></div></div>   
    </div>
    </div>
    </div> 