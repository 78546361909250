<div class="sidebar justify-content-center">
<div class="d-flex justify-content-center">
<div class="navbar-brand">
<div class="nav-left-logo">
    <img class="img-fluid text-center" src="../../../assets/images/logo.svg" alt="image not found" width="auto" height="auto">

</div> </div>
</div>
<a class="nav-item active"   *ngIf="IsShowDashboardLink && (href == DashboardRoute.SuperDashboard || href == DashboardRoute.ClientDashboard || href == DashboardRoute.AgentDashboard || href == DashboardRoute.systemDashboard)" 
(click)="MoveToDashboard()"><img class="img-fluid icons-change" src="../../../assets/images/admin-dashboard.svg"  alt="image not found">Admin Dashboard</a>

<a class="nav-item"   *ngIf="IsShowDashboardLink && (href != DashboardRoute.SuperDashboard && href != DashboardRoute.ClientDashboard && href != DashboardRoute.AgentDashboard && href != DashboardRoute.systemDashboard)" 
(click)="MoveToDashboard()"><img class="img-fluid icons-change" src="../../../assets/images/admin-dashboard.svg"  alt="image not found">Admin Dashboard</a>

<div class="v-bottom">
<div class="box-profile">
<div class="img-src-id">
<img class="img-fluid img-src-id" src="../../../../assets/images/user_id.png" alt="image not found"></div>
<div class="name-text">{{userFullName}} </div>
<div class="number-text">{{UserRandomId}}</div>
<div class="email-id">{{email}}</div>
</div> 
     
<a class="nav-item" (click)="Logout()"><img class="img-fluid icons-change" src="../../../../assets/images/logout-icon.svg" alt="image not found">logout</a>
<div class="text-center d-flex justify-content-center footer-text">Copyright © {{year}}</div>
</div>
</div>
