import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtercomdata'
})
export class FilterComdataPipe implements PipeTransform {

  transform(companydata: any[], filter: string): any {
    if (!filter) {
      return companydata;
    }
    filter = filter.replace(/^(.)|\s+(.)/g, c => c.toLowerCase());
    var result = companydata.filter(item => item.companyName.toLowerCase().startsWith(filter) == true).slice(0, 5);
    return result;
  }
}
