<div class="login-page-conatiner">
    <div id="login-wrapper-logo">
        <img class="img-fluid text-center" src="../../../assets/images/Logo-white.svg" alt="image not found" width="auto" height="auto">

    </div>
    <div *ngIf="deactive" class="alert-popup-box">
        <div class="d-flex">
          <div class="box-close-x"></div>
          <div><img class="img-close-x" src="../../../assets/images/alert.svg" alt="image not found"></div>
          <div class="alert-text-css">You will not be able to access Blossm application because your account is marked inactive by your employer.</div>
          <button type="button" (click)="close()" class="close-x"><img src="../../../assets/images/Vector-btn.svg"></button>
        </div>
        </div>
    <div class="container">
        <div class="justify-content-center">
            <div class="login-width">
                <div class="login-features-name"> Sign in to your account</div>
                <form [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
                    <div id="login-form-conatiner">
                        <!-- field one -->
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" formControlName="UserName" [(ngModel)]="userAuthentication.UserName"
                                maxlength="60" 
                                class="form-control text-box-h" 
                                (focus)="IsrequiredMsg=false" (focusout)="checkValidUserName($event)"
                                (focus)="show_error_message=false"
                                placeholder="Username"
                                [ngClass]="{'is-invalid':getAllFormControls.UserName.touched && getAllFormControls.UserName.errors}">

                            <div class="text-danger">
                                <div *ngIf="IsrequiredMsg">Username is required</div>
                            </div>
                        </div>
                        <!-- field second -->
                        <div class="form-group">
                            <label>Password</label>
                            <input type="{{type}}" formControlName="Password" [(ngModel)]="userAuthentication.Password"
                                autocomplete="new-password"
                                onKeyPress="if(this.value.length==32) return false;"
                                (focusout)="checkValidPassword($event)" (focus)="IsPasswordRequiredMsg=false"
                                (focus)="IsPasswordLengthValid=false" 
                                (focus)="show_error_message=false"
                                [ngClass]="{'is-invalid':getAllFormControls.Password.touched && getAllFormControls.Password.errors}"
                                placeholder="Password" class="form-control text-box-h">
                            <em class="eye-icon hover-cursor" (click)="ShowHidePass()">
                                <img id="open-eye" class="img-fluid" src="{{img_src}}" alt="image not found"
                                    width="auto" height="auto"></em>

                            <div class="text-danger">
                                <div *ngIf="IsPasswordRequiredMsg || IsPasswordLengthValid">Please enter a valid
                                    password</div>
                            </div>
                            <div class="text-danger">
                                <div *ngIf="show_error_message">{{result}}</div>
                            </div>
                        </div>
                        <div id="btn-sign-in-login">
                            <button class="login-btn-login">
                                <div class="container" *ngIf="IsImageLoaded">
                                    <div style="margin:1% auto;">
                                        <div class="spinner-border custom-spinner-border" role="status"></div>
                                    </div>
                                </div>
                                <span *ngIf="!IsImageLoaded">Sign In </span>
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<button #SessionModal [hidden]="true" data-toggle="modal" (click)="open(loggedoutmodal)" data-target=".bd-example-modal-lg3"></button>
<ng-template #loggedoutmodal let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="img-icon text-center">
          <img class="img-fluid" src="../../../assets/images/watch.png" alt="image not found">
        </div>
        <div class="info-container">
          <div class="info-txt">For your security we have logged you out automatically.</div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-still" data-dismiss="modal" aria-label="Close" (click)="okay(loggedoutmodal)">Okay</button>
        </div>
        
      </div>
    </div>
  </div>
</ng-template>