<div class="login-page-conatiner">
<div id="login-wrapper-logo">
</div>
<div class="login-features-name">Hello FirstName. Activate your account</div>
<form>
<div id="login-form-container">
<div class="text-name">Username</div>
<input type="text" class="form-control input-field"  placeholder="Enter username">

<input type="text" class="form-control input-field"  placeholder="Set Password">
<em class="eye-icon hover-cursor" (click)="ShowHidePass()">
<img id="open-eye" class="img-fluid" src="{{img_src}}" alt="image not found" width="auto" height="auto" ></em>  


<div>
<ul class="validation-requirmet">
        <li [ngClass]="IsUpperLowerCase ? 'green ' : 'cross'">
            An uppercase & lowercase character
        </li>
        <li [ngClass]="IsNumber ? 'green ' : 'cross'">
            Please provide at least one number
        </li>

        <li [ngClass]="Islength ? 'green ' : 'cross'">
            Password must be between 8-20 characters
        </li>
    </ul>
</div>
<div id="btn-sign-in-wrapper">
<button type="button" class="btn sg-btn">Submit</button>
</div>



</div></form>
</div>

