import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { UserRights } from '../UserRights/userRights';

@Injectable()
export class AuthGuard implements CanActivate {

  idToken: string;
  mnuList: any[] = [];
  userDomain: string;
  constructor(private router: Router, private _avRoute: ActivatedRoute, private userrights: UserRights, private _dmsService: DmsasService) {
    this.idToken = sessionStorage.getItem('token');
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (typeof this.idToken != 'undefined' && this.idToken) {
      let currentURL = window.location.href;      
      this.userDomain = this._dmsService.getDomainName(this.idToken);            
      let domain = currentURL.indexOf('localhost') > -1 ? 'localhost' : currentURL.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i)[1];
      
      if (this.userDomain.toLowerCase() != domain.toLowerCase() && domain.toLowerCase() != 'localhost') {
        console.log('you are not authrozied to access this url: ' + currentURL);
        this.router.navigate(['/error-page']);
        return false;
      }
      
      this.mnuList = this.userrights.decodeToken();
      let role = next.data.role;
      if (this.mnuList.findIndex(cc => cc == role) != -1) {
        return true;
      }
      else {
        console.log('you are not authrozied to access this url: ' + currentURL);
        this.router.navigate(['/error-page']);
        return false;
      }      
    }
    else {
      console.log("Tocheck login redirection: 2auth.guard2.component");
      this.router.navigate(['/error-page']);
      return false;
    }
  }

}
