import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-debts',
  templateUrl: './invoice-debts.component.html',
  styleUrls: ['./invoice-debts.component.css']
})
export class InvoiceDebtsComponent implements OnInit {

  constructor() {
    // Do nothing
   }

  ngOnInit(): void {
    // Do nothing
  }

}
