import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './Pages/login/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthGuard } from './Extras/AuthGuard/auth.guard';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ActivateAccountPageComponent } from './Pages/sign-up/activate-account-page/activate-account-page.component';
import { CompanyInvoiceComponent } from './Pages/company-invoice/company-invoice.component';
//import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorPageComponent } from './Components/error-page/error-page.component';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { InactivityTimerComponent } from './Components/inactivity-timer/inactivity-timer.component';
import { LoginFailedComponent } from './Pages/login-failed/login-failed.component';
import { AuthenticationInterceptor } from './Extras/Interceptors/auth.interceptor';
//import { BulkUploadComponent } from './Pages/bulk-upload/bulk-upload.component';
import { FilterCompanyDataModule } from './Extras/CustomPipes/filtercompanydata.module';
import { ActivateUserPasswordComponent } from './Pages/activate-user-password/activate-user-password.component';
Sentry.init({
  dsn: "https://45796b84698c488ab23fa98a01951c68@o263113.ingest.sentry.io/6439403" ,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracingOrigins: ["localhost", environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // integrations: [new TracingIntegrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,    
    ActivateAccountPageComponent,    
    CompanyInvoiceComponent, 
    ErrorPageComponent,
    InactivityTimerComponent,
    LoginFailedComponent,
   // BulkUploadComponent,
    ActivateUserPasswordComponent        
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    FilterCompanyDataModule,
    //NgMultiSelectDropDownModule.forRoot()
  
    
  ],  
  providers: [DatePipe, AuthGuard, BnNgIdleService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
{
provide:HTTP_INTERCEPTORS,
useClass:AuthenticationInterceptor,
multi:true
}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
