<app-nav-superadmin></app-nav-superadmin>
<div class="body-bg container-fluid">
  <div class="row">
    <div class="col-12">
      <div *ngIf="this.UserTypes === '2'" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/company-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp; Invoices
        for company</div>
      <div *ngIf="systemAdminBackBtn" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/systemadmin-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp;
        Invoices for company</div>
      <div *ngIf="superAdminBackBtn" class="recent-heading"><em routerLinkActive="active" [routerLink]="['/superadmin']"
          style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp; Invoices for company </div>

      <div class="table-box">
        <div class="table-div">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="padding-left-0">Purchase Date</th>
                <th scope="col">Company Name</th>
                <th scope="col">Invoice Description</th>
                <th scope="col">Amount</th>
                <th scope="col">VAT(20%)</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Payment type</th>
                <th scope="col" class="text-center padding-right-0">Download</th>
              </tr>
            </thead>
            <tbody *ngIf="!IsDataFound">
              <tr *ngFor="let item of transactionList ">
                <td class="td-child padding-left-0">{{item.purchaseDate | date: 'MM/dd/yyyy'}}</td>
                <td class="td-child">{{item.clientName}}</td>
                <td class="td-child">
                <ng-template [ngIf]="item.assetSearch > 0" #temp1>Asset Search</ng-template>
                <span *ngIf="item.assetSearch > 0 && (item.creditSearch > 0 || item.openBanking > 0 || item.sms > 0)">&#44;&nbsp;</span>
                <ng-template [ngIf]="item.creditSearch > 0" #temp2>Credit Search</ng-template>
                <span *ngIf="item.creditSearch > 0 && (item.openBanking > 0 || item.sms > 0)">&#44;&nbsp;</span>
                <ng-template [ngIf]="item.openBanking > 0" #temp3>Open Banking</ng-template>
                <span *ngIf="item.openBanking > 0 &&  item.sms > 0">&#44;&nbsp;</span>
                <ng-template [ngIf]="item.sms > 0" #temp4>SMS</ng-template>
                </td>
                
                <td class="td-child" *ngIf="item.amount =='0'; else amountview">-</td>
                <ng-template #amountview>
                  <td class="td-child">&#163;{{item.amount | number : '1.2-2'}}</td>
                </ng-template>
                
                <td class="td-child" *ngIf="item.vatAmount =='0'; else vatAmountview">-</td>
                <ng-template #vatAmountview>
                  <td class="td-child">&#163;{{item.vatAmount | number : '1.2-2'}}</td>
                </ng-template>
                
                <td class="td-child" *ngIf="item.amount =='0'; else totalAmountview">-</td>
                <ng-template #totalAmountview>
                  <td class="td-child">&#163;{{item.amount + item.vatAmount | number : '1.2-2'}}</td>
                </ng-template>                
                
                <td class="td-child">{{item.status}}</td>

                <td class="td-child text-center padding-right-0">
                  <div (click)="downloadInvoicePDF(item.transactionId)" style="cursor:pointer">
                    <img src="../../../assets/images/CTA_pdf.svg" class="img-fluid" alt="image not found">
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="IsDataFound">
              <tr>
                <td colspan="8">
                  <div class="texttable">No records found</div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="loader" class="text-center">
              <tr>
                <td colspan="8">
                  <div id="loader">
                    <div class="text-center">
                      <div class="spinner-border" role="status"></div>
                      <div class="textloader">Please wait while we are gathering data …</div>
                    </div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>