<app-nav-superadmin (NewSearchClick)="searchClicked($event)"></app-nav-superadmin>
<div class="body-bg container-fluid">
  <div *ngIf="!loader">
    <div class="top-heading">Admin Controls</div>
    <div class="row">
      <div class="col-4 p-r-10 p-l-10" routerLinkActive="active" [routerLink]="['/company-profile']">
        <div class="box-user">
          <img class="img-fluid text-center height-img" src="../../../assets/images/client-icon.svg"
            alt="image not found" width="auto" height="auto">
          <div class="client-text">Company Profile</div>
          <div class="text-bottomline">View and mange company profile information</div>
        </div>
      </div>
      <div class="col-4 p-l-10" routerLinkActive="active" [routerLink]="['/search-employee']">
        <div class="box-user">
          <img class="img-fluid text-center height-img" src="../../../assets/images/user-icon.svg" alt="image not found"
            width="auto" height="auto">
          <div class="client-text">Manage Employees</div>
          <div class="text-bottomline">Add new employees and edit/remove existing employees.</div>
        </div>
      </div>
      <div class="col-4 p-r-10 p-l-10" *ngIf="IsWageAdvanceExists">
        <div class="box-user">
          <img class="img-fluid text-center" src="../../../assets/images/wage.svg" alt="image not found" width="auto"
            height="auto">
          <div class="client-text">Earned Wage Advance</div>
          <div class="text-bottomline">View and track earned wage advance transaction for the company employees</div>
        </div>
      </div>
      <div class="col-4 p-l-10" routerLinkActive="active" [routerLink]="['/invoices']">
        <div class="box-user">
          <img class="img-fluid text-center height-img" src="../../../assets/images/Invoices.svg" alt="image not found"
            width="auto" height="auto">
          <div class="client-text">Invoices</div>
          <div class="text-bottomline">View all current and previous invoices with status</div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
    invoice template will come here
    </div> -->
    <div class="top-heading">Top metrics</div>
    <div class="box-credit">
      <div class="d-flex">
        <div class="col-6 new-padding">
          <div class="text-heading-set">Total Payouts this Cycle</div>
          <div class="bg-box">
            <div class="d-flex">
              <div class="d-flex margin-p">
                <img class="img-fluid img-box" src="../../../assets/images/credit-used.png" alt="image not found"
                  width="auto" height="auto">
                <div>
                  <div class="text-al">Credits Used</div>
                  <div class="text-num"></div>
                </div>
              </div>
              <div class="border-x"></div>
              <div class="d-flex margin-o">
                <img class="img-fluid img-box" src="../../../assets/images/credit-available.png" alt="image not found"
                  width="auto" height="auto">
                <div>
                  <div class="text-al">Credits Available</div>
                  <div class="text-last"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 new-padding-0">
          <div class="d-flex justify-content-between">
            <div class="text-heading-set">Deposit Available</div>
            <div class="button-download"><button type="button" class="btn btn-report"><img class="img-fluid"
                  src="../../../assets/images/download-icon.svg" alt="image not found" width="auto" height="auto">
                &nbsp; Download Report</button></div>
          </div>
          <div class="bg-box">
            <div class="d-flex">
              <div class="d-flex margin-p">
                <div class="img-month color-white">Jan</div>
                <div>
                  <div class="text-al">Credits Used</div>
                  <div class="text-num"></div>
                </div>
              </div>
              <div class="border-x"></div>
              <div class="d-flex margin-o">
                <div class="img-month color-white">Jan</div>
                <div>
                  <div class="text-al">Credits Available</div>
                  <div class="text-last"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6 new-padding">
          <div class="text-heading-set">Employees Signed Up</div>
          <div class="box-bg">
            <div class="d-flex">
              <div class="d-flex margin-p">
                <div class="img-month">Jan</div>
                <div>
                  <div class="text-al">Credits Used</div>
                  <div class="text-num"></div>
                </div>
              </div>
              <div class="border-x"></div>
              <div class="d-flex margin-o">
                <div class="img-month">Jan</div>
                <div>
                  <div class="text-al">Credits Available</div>
                  <div class="text-last"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 new-padding-0">
          <div class="text-heading-set">Average Transaction Value</div>
          <div class="box-bg">
            <div class="d-flex">
              <div class="d-flex margin-p">
                <div class="img-month">Jan</div>
                <div>
                  <div class="text-al">Credits Used</div>
                  <div class="text-num"></div>
                </div>
              </div>
              <div class="border-x"></div>
              <div class="d-flex margin-o">
                <div class="img-month">Jan</div>
                <div>
                  <div class="text-al">Credits Available</div>
                  <div class="text-last"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="loader" class="text-center">
    <div colspan="4">
      <div id="loader">
        <div class="text-center">
          <div class="spinner-border" role="status"></div>
          <div class="textloader">Please wait while we are gathering data …</div>
        </div>
      </div>
    </div>
  </div>
</div> -->
  <!-- Modal -->
  <!-- <ng-template #buycreditmodal let-modal>
  <div class="modal-body modal-padding-new">
    <div><button (click)="modal.dismiss('Cross click')" type="button" aria-label="Close" class="close close-btn">
        <img src="../../../assets/images/close-btn.svg" alt="image" class="img-fluid"></button></div>
    <div class="text-center">
      <img class="img-fluid" src="../../../assets/images/No-credits.svg" alt="image">
    </div>
    <div class="delete-header">No credits available</div>
    <div class="text-delete">There are currently no credits left in your account for credit searches. Please add more
      credits.</div>
    <div class="d-flex justify-content-center" id="margin-y">
      <button type="button" (click)="BuyCredits()" class="btn btn-submit">Buy Credits</button>
    </div>
  </div>
</ng-template> -->