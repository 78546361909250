import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-agentadmin',
  templateUrl: './agentadmin.component.html',
  styleUrls: ['./agentadmin.component.css']
})
export class AgentadminComponent implements OnInit {
  IsSearchPageOpen:number=0;
  @ViewChild('crcomponent') crcomponent;
  constructor() { 
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }
  searchClicked(event)
  {
  this.IsSearchPageOpen=event;
  }

  CustomerAddedEvent(event)
  {
    this.crcomponent?.enableCustomer();
  }
}
