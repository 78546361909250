// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import * as config from '../assets/app-config.json';
export const environment = {
  configUrl: config,
  production: false,
  name: 'Local',
  imgBasURL: '../../../assets/blossm-ui/images/',
  imgbaseurlbulk:'../../../assets/blossm-ui/bulkupload/',
  sampleFileUrl:"https://assets.dev.headstone.life/app-assets/files/SampleBulkUpload.csv",
  apiUrl: 'https://api.dev.blossm.me',
  ookiApiUrl: 'https://api.dev.ooki.io',
  domainUrl: 'dev.blossm.me',
  TruelayerAuthorizationLink: 'https://auth.truelayer.com/?response_type=code&client_id=scoregenius-khwd&nonce=4206444574&scope=info%20accounts%20balance%20transactions%20cards%20direct_debits%20standing_orders%20offline_access&redirect_uri=http://localhost:4200/callback&providers=uk-ob-all%20uk-oauth-all%20uk-cs-mock',
  secretKey: '8D5A11EB-139D-4F48-A710-32E9D5114ABF',
  partnerId: '2C943A4B-45CE-4A66-B98D-3A61F8F8AD80',
  OpenBankingLink: 'https://openbanking.dev.blossm.me'
};
