import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthentication } from 'src/app/Models/dmsModel';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { environment } from 'src/environments/environment';
import { userTypeEnum } from '../../../Extras/UserRights/userRights'
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class LoginComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  LoginForm: FormGroup;
  IsImageLoaded: boolean = false;
  IsPasswordRequiredMsg: boolean = false;
  IsPasswordLengthValid: boolean = false;
  img_src = this.imgSrc + 'eye.svg';
  show = false;
  type = 'password';
  show_error_message: boolean = false;
  IsEmailNotValid: boolean = false;
  IsrequiredMsg: boolean = false;
  returnUrl: string;
  result: string = '';
  deactive: boolean = false;
  @ViewChild('SessionModal') SessionModal: ElementRef;

  userAuthentication = new UserAuthentication();
  constructor(config: NgbModalConfig, private route: Router, private _dmsService: DmsasService, private modalService: NgbModal,
    private activatedRoute: ActivatedRoute, private fb: FormBuilder) {

    config.backdrop = 'static';
    config.keyboard = false;
  }
  open(content: any) {
    this.modalService.open(content,
      { windowClass: 'n-class' }
    );
  }

  ngAfterViewInit(): void {
    this.checkModal();
  }
  ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    sessionStorage.removeItem('UserName');
    sessionStorage.removeItem('FName');
    this.LoginForm = this.fb.group({
      UserName: ['', [Validators.required]],
      Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32)]],
      checkbox: [false]
    });
  }

  get getAllFormControls() {
    return this.LoginForm.controls;
  }

  onSubmit() {
    if (this.LoginForm.invalid) {
      this.IsImageLoaded = false;
      if (!this.LoginForm.controls["UserName"].value) {
        this.IsrequiredMsg = true;
      }
      if (!this.LoginForm.controls["Password"].value) {
        this.IsPasswordRequiredMsg = true;
      }
      return;
    }

    sessionStorage.removeItem('name');
    sessionStorage.removeItem('phone');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('sessionTimeout');
    this.show_error_message = false;
    this.IsImageLoaded = true;

    this.userAuthentication.Subdomain = this.getSubdomain();
    this._dmsService.Login(this.userAuthentication).subscribe((res: any) => {

      if (res?.statusCode == 410) {
        this.route.navigate(["/login-failed"]);
      }

      else if (res?.statusCode == 1 && res?.result !== null) {
        sessionStorage.setItem('name', res.result.fName + ' ' + res.result.lName);
        sessionStorage.setItem('phone', res.result.phone);
        sessionStorage.setItem('email', res.result.email);
        sessionStorage.setItem('token', res.result.token);
        sessionStorage.setItem('UserRandomId', res.result.userRandomId);
        sessionStorage.setItem('type', res.result.userTypeId);
        sessionStorage.setItem('comtype', res.result.companyType);

        let userTypeId: number = res.result.userTypeId;
        switch (userTypeId) {
          case userTypeEnum.superAdmin:
            this.route.navigate(['/superadmin']);
            break;
          case userTypeEnum.systemAdmin:
            this.route.navigate(['/systemadmin-dashboard']);
            break;
          case userTypeEnum.companyAdmin:
            sessionStorage.setItem('ClientName', res.result.clientName);
            sessionStorage.setItem('ClientId', String(res.result.companyId));
            sessionStorage.setItem('ClientEmail', String(res.result.clientEmail));            
            this.route.navigate(['/company-dashboard']);
            break;
          case userTypeEnum.agent:
            sessionStorage.setItem('ClientName', res.result.clientName);
            sessionStorage.setItem('ClientId', String(res.result.companyId));
            sessionStorage.setItem('ClientEmail', String(res.result.clientEmail));
            this.route.navigate(['/dashboard']);
            break;
          default:
            break;
        }
      }else if(res?.statusCode == 4){
        this.deactive = true;
        this.IsImageLoaded = false;
      }else if(res?.statusCode == 5){
        this.show_error_message = true;
        this.IsImageLoaded = false;
        this.result = "Username doesn't exist. Please enter the correct email address";
      }else if(res?.statusCode == 6){
        this.show_error_message = true;
        this.IsImageLoaded = false;
        this.result = 'Invalid Password. Please contact Blossm support';
      }
      else {
        this.IsImageLoaded = false;
        this.show_error_message = true;
        this.result = res.status;
      }

    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
      this.IsImageLoaded = false;
      this.show_error_message = true;
      this.result = 'Something went wrong';
    });
  }

  close(){
    this.deactive = false;
  }
  ShowHidePass() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.img_src = this.imgSrc + 'eye-green.svg';
    } else {
      this.type = 'password';
      this.img_src = this.imgSrc + 'eye.svg';
    }
  }

  checkValidPassword(e: any) {
    this.show_error_message = false;
    let password = e.target.value;
    if (password == '') {
      this.IsPasswordRequiredMsg = true;
      this.IsPasswordLengthValid = false;
    } else {
      this.IsPasswordRequiredMsg = false;
      if (password.length < 8) {
        this.IsPasswordLengthValid = true;
      } else {
        this.IsPasswordLengthValid = false;
      }
    }
  }

  checkValidUserName(e: any) {
    this.show_error_message = false;
    let userName = e.target.value;
    if (userName == '') {
      this.IsrequiredMsg = true;
      this.IsEmailNotValid = false;
    } else {
      this.IsrequiredMsg = false;
    }
  }

  getSubdomain(): string {
    let currentURL = window.location.href;
    let domain = currentURL.indexOf('localhost') > -1 ? 'localhost' : currentURL.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i)[1];
    return domain;
  }
  okay(content) {
    this.modalService.dismissAll(content);
  }
  checkModal() {
    if (sessionStorage.getItem('sessionTimeout') == '1') {
      this.SessionModal.nativeElement.click();
      sessionStorage.clear();
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }

  }

}
