import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-invoice',
  templateUrl: './company-invoice.component.html',
  styleUrls: ['./company-invoice.component.css']
})
export class CompanyInvoiceComponent implements OnInit {

  constructor() { 
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }

}
