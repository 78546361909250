
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';


export enum userTypeEnum {
    agent = 1,
    companyAdmin=2,
    systemAdmin=3,
    superAdmin=4,
  }

@Injectable({
    providedIn: 'root'
})
export class UserRights {


    decodedToken: any;
    mnu: string = '';
    mnuList: any[] = [];

    constructor() { 
        // Do nothing
    }

    decodeToken() {
        
        let token = sessionStorage.getItem('token');
        this.decodedToken = jwt_decode(token);
        this.mnu = this.decodedToken.mnu;
        this.mnuList = this.mnu.split(',');
        return this.mnuList;
    }

    GetUserType() {
        
        let token = sessionStorage.getItem('token');
        this.decodedToken = jwt_decode(token);
        return this.decodedToken.usertype;
    }
}


