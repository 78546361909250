<app-nav-superadmin></app-nav-superadmin>
<div class="body-bg container-fluid">
  <div class="row">
    <div class="col-12">

      <div *ngIf="agentAdminBackBtn" class="recent-heading"><em routerLinkActive="active" [routerLink]="['/dashboard']"
          style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp; Manage Employees</div>
      <div *ngIf="clientAdminBackBtn" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/company-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp;
          Manage Employees</div>
      <div *ngIf="systemAdminBackBtn" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/systemadmin-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp;
          Manage Employees</div>
      <div *ngIf="superAdminBackBtn" class="recent-heading"><em routerLinkActive="active" [routerLink]="['/superadmin']"
          style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp; Manage Employees</div>

      <div class="table-box">
        <div class="input-div d-flex">
          <input type="text" style="background: url(../../../../assets/images/Searches_icon.svg) no-repeat  1%;"
            class="txt form-control" placeholder="Search employees" width="80%"
            [(ngModel)]="searchText" />
            <button type="button" class="btn import-filter"  [routerLink]="['/import-employees']">Import Employees</button>
            <!-- <button  type="button" class="btn filter-icon margin-r" (click)="open(importemployees)"><img class="img-fluid text-center" src="../../../assets/images/filter-icon.svg"></button>-->
            <button  type="button" class="btn filter-icon" (click)="downloadCSV()"><img class="img-fluid text-center" src="../../../assets/images/download-btn.svg"></button>
         
          
        </div>
        <div class="table-div">
          <table class="table">
            <thead>
              <tr style="cursor: default">
                <th scope="col" class="padding-left-0">Name</th>
                <th scope="col">Company Name</th>
                <th *ngIf="!clientAdminBackBtn" scope="col">Experian</th>
                <th scope="col">Bank </th>
                <th scope="col">Last Login </th>
                <th scope="col">Status </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of  (cusomerList | filterdata: searchText);trackBy: trackByFn;">
                <div *ngIf="item === -1; then NoFiltered"></div>

                <td class="padding-left-0" *ngIf="item !== -1" [ngClass]="item.status == 1 ? 'td-child' : 'td-child inactive'">{{item.firstName}} {{item.lastName}}<br/> <span style="font-size: 11px">{{item.emailAddress}}</span></td>
                <td *ngIf="item !== -1" [ngClass]="item.status == 1 ? 'td-child' : 'td-child inactive'"> {{item.companyName}}<br/><span style="font-size: 11px">{{item.companyNumber}}</span></td>
                <td *ngIf="item !== -1  && !companyStatus && !clientAdminBackBtn" [ngClass]="item.status == 1 ? 'td-child' : 'td-child inactive'"> {{item.experian}} </td>
                <td *ngIf="item !== -1  && !companyStatus" [ngClass]="item.status == 1 ? 'td-child' : 'td-child inactive'" > {{item.account}} </td>
                <td *ngIf="item !== -1" [ngClass]="item.status == 1 ? 'td-child' : 'td-child inactive'">{{item.lastLogin}}</td>
                <td *ngIf="item !== -1" [ngClass]="item.status == 1 ? 'td-child' : 'td-child inactive'" >
                  <div *ngIf="item.status==3;else content">Pending Activation</div>
                <ng-template #content><div *ngIf="item.status==1;else content2">Active</div></ng-template>
                <ng-template #content2>Inactive</ng-template>
                </td>
    
                <td *ngIf="item !== -1" class="td-child text-righttt" style="cursor: pointer;">
                  <div class="dropdown dropleft" (click)="openmenu()">
                    <a class="btn-new-b dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="assets\dsms-assets\dot_n.svg" alt="image not found" />
                    </a>
                    <div class="dropdown-menu-new dropdown-menu" id="padding-menu" aria-labelledby="dropdownMenuButton">
                      <div class="dropdown-item" (click)="ViewUser(viewdetailsmodal,item)">View</div>
                      
                      <!-- <div class="dropdown-item" (click)="ViewUser(viewdetailsmodal,item)">View</div>
                      <div class="dropdown-item" (click)="EditUser(editdetailsmodal,item,'')">Edit</div>-->
                      <div class="dropdown-item" *ngIf="item.status == 1" 
                        (click)="ChangeStatus(2,item.empId)">Deactivate</div>
                      <div class="dropdown-item" *ngIf="item.status == 2"
                        (click)="ChangeStatus(1,item.empId)">Activate</div>
                      <div class="dropdown-item" data-toggle="modal" data-target="#empdelete"
                        (click)="openDeleteModal(item.empId)">Delete</div>
                    </div>
                  </div>
            
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="loader" class="text-center">
              <td colspan="6">
                <div id="loader">
                  <div class="text-center">
                    <div class="spinner-border" role="status"></div>
                    <div class="textloader">Please wait while we are gathering data …</div>
                  </div>
                </div>
              </td>
            </tbody>
            <tbody *ngIf="noData">
              <td colspan="6">
                <div class="texttable">No records found</div>
              </td>
            </tbody>
            <ng-template #NoFiltered>
              <td colspan="6">
                <div class="texttable">No records found</div>
              </td>
            </ng-template>
          </table>
        </div>
      </div>
       <!-- View User Modal -->
       <ng-template #viewdetailsmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div class="modal-title" id="modal-basic-title">View Employee Details</div>
            <button type="button" class="close close-btn" aria-label="Close"
              (click)="modal.dismiss('Cross click');UserForm.reset()">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="UserForm">
              
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Company</div>
                <div class="text-value">{{employeeData?.companyName}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">First Name</div>
                <div class="text-value">{{employeeData?.firstName}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Last Name</div>
                <div class="text-value">{{employeeData?.lastName}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Email</div>
                <div class="text-value">{{employeeData?.emailAddress}}</div>
              </div>
              <div *ngIf="employeeData?.employeeId" class="d-flex justify-content-between border-bn">
                <div class="text-name">Employee Id</div>
                <div class="text-value">{{employeeData?.employeeId}}</div>
              </div>
              <div *ngIf="(employeeData?.contactNumber)" class="d-flex justify-content-between border-bn">
                <div class="text-name">Contact Number</div>
                <div class="text-value">{{employeeData?.contactNumber}}</div>
              </div>
              <div *ngIf="(employeeData?.dateOfJoining && employeeData?.dateOfJoining !='1900-01-01T12:00:00')" class="d-flex justify-content-between border-bn">
                <div class="text-name">Date of Joining</div>
                <div class="text-value">{{employeeData?.dateOfJoining}}</div>
              </div><div *ngIf="employeeData?.monthlyNetPay !=0" class="d-flex justify-content-between border-bn">
                <div class="text-name">Monthly Net Pay</div>
                <div class="text-value">£{{employeeData?.monthlyNetPay | number : '1.2-2'}}</div>
              </div><div *ngIf="employeeData?.sortCode" class="d-flex justify-content-between border-bn">
                <div class="text-name">SORT code</div>
                <div class="text-value">{{employeeData?.sortCode}}</div>
              </div><div *ngIf="(employeeData?.bankAccountNumber)" class="d-flex justify-content-between border-bn">
                <div class="text-name">Bank Account Numberddd</div>
                <div class="text-value">{{employeeData?.bankAccountNumber}}</div>
              </div>
            </form>
            <div class="btn-box">
              <!-- <button class="btn btn-submit-modal" type="submit"
                (click)="EditUser(editdetailsmodal,'',UserId);modal.dismiss('Cross click')">Edit Details</button> -->
            </div>
          </div>
        </div>
      </ng-template>
       <!-- Modal -->
       <div class="modal fade" id="empdelete" tabindex="-1" role="dialog" aria-labelledby="insidemodalTitle"
       aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered" role="document">
         <div class="modal-content" id="modal-inside">
           <div class="modal-body">
             <div class="text-center">
               <img class="img-fluid" src="../../../assets/images/delete-icon.svg" alt="image">
             </div>
             <!-- <div class="delete-header">Delete {{selectedEmployee['emailAddress']}}?</div>
             <div class="text-delete">Are your sure want to delete Employee <strong>{{selectedEmployee['firstName']}} {{selectedEmployee['lastName']}}</strong> ?</div> -->
             <div class="text-delete marginx">Are your sure want to delete employee <strong>{{selectedEmployee?.firstName}} {{selectedEmployee?.lastName}}</strong> ?</div>
             <div class="d-flex" id="margin-y">
               <button type="button" class="btn btn-no" (click)="closeModal()" data-dismiss="modal">No</button>
               <button type="button" class="btn btn-submit btn0-w" (click)="ChangeStatus(0,selectedEmployee.empId)">Yes</button>
             </div>
           </div>
         </div>
       </div>
     </div>
    </div>
  </div>
  <!-- Modal -->
  <ng-template #importemployees let-modal>
    <div class="modal-content-0">
      <div class="modal-header">
        <div class="modal-title" id="modal-basic-title">Filter</div>
        <button type="button" class="close close-btn" aria-label="Close"
          (click)="modal.dismiss('Cross click');">
          <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
        </button>
      </div>
      <div class="modal-body padding-top">
        <div class="border-bn">
        <div class="d-flex justify-content-between">
          <div class="filterparatext">Company Name</div>
          <div class="Clear">Clear</div>
        </div>
        <input  type="text" style="background: url(../../../../assets/images/Searches_icon.svg) no-repeat 1%;" class="inputsearch form-control" placeholder="Search" />
        </div>
        <div class="border-bn margin-n">
          <div class="d-flex justify-content-between">
            <div class="filterparatext">Employee ID/Name</div>
            <div class="Clear">Clear</div>
          </div>
          <input  type="text" style="background: url(../../../../assets/images/Searches_icon.svg) no-repeat 1%;" class="inputsearch form-control" placeholder="Search" />
          </div>
          <div class="border-bn margin-n">
            <div class="d-flex justify-content-between">
              <div class="filterparatext">Experian Verification</div>
              <div class="Clear">Clear</div>
            </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-active-new btn-Success-failed" type="submit">Success</button>
            <button class="btn btn-Success-failed" type="submit">Failed</button>

          </div> 
          </div>
          <div class="border-bn margin-n">
            <div class="d-flex justify-content-between">
              <div class="filterparatext">Bank Verification</div>
              <div class="Clear">Clear</div>
            </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-active-new btn-Success-failed" type="submit">Success</button>
            <button class="btn btn-Success-failed" type="submit">Failed</button>

          </div> 
          </div>
          <div class="border-bn margin-n">
            <div class="d-flex justify-content-between">
              <div class="filterparatext">Status</div>
              <div class="Clear">Clear</div>
            </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-active-new btn-Success-failed active-width" type="submit">Active</button>
            <button class="btn btn-Success-failed" type="submit">Pending Activation</button>
            <button class="btn btn-Success-failed active-width" type="submit">Inactive</button>
          </div> 
          </div>
        <div class="btn-box">
          <button class="btn btn-submit-modal" type="submit">Apply</button>
        </div>
      </div>
    </div>
  </ng-template>
<!-- Modal -->