<app-nav-superadmin></app-nav-superadmin>
<div class="body-bg container-fluid">
  <div class="row">
    <div class="col-12">
      <div *ngIf="systemAdminBackBtn" class="recent-heading"><em routerLinkActive="active"
          [routerLink]="['/systemadmin-dashboard']" style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp;
        Manage Company</div>
      <div *ngIf="superAdminBackBtn" class="recent-heading"><em routerLinkActive="active" [routerLink]="['/superadmin']"
          style="cursor: pointer;" class="fas fa-arrow-left"></em> &nbsp;Manage Company</div>
      <div class="table-box">
        <div class="input-div d-flex">
          <input type="text" class="txt"
            style="background: url(../../../../assets/images/Searches_icon.svg) no-repeat  1%;"
            placeholder="Search current company" width="100%" [(ngModel)]="searchText" />
          <button class="btn btn-submit" type="submit"  [routerLink]="['/company-profile']">Add
            Company</button>
        </div>
        <div class="table-div">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="padding-left-0">Company Details</th>
                <th scope="col">Contact Details</th>
                <th scope="col">No. of Users</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngIf="!IsDataFound">
              <tr *ngFor="let item of (clientType | filterdata: searchText);trackBy:trackbyId">
                <div *ngIf="item === -1; then NoFiltered"></div>
                <td *ngIf="item !== -1" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'"
                  class="td-child padding-left-0"  [routerLink]="['/company-profile']" [queryParams]="{companyId:item.companyId}">
                  {{item.name}}<br>{{item.number}}</td>
                <td *ngIf="item !== -1" [routerLink]="['/company-profile']" [queryParams]="{companyId:item.companyId}" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'"><span
                    *ngIf="item.title != 'Other'" > {{item.title}}</span>
                  {{item.fName}}
                  {{item.lName}}<br>{{item.email}}<br>{{item.phoneNumber}}</td>
                <td *ngIf="item !== -1 && item?.companyType == 'D'"
                  [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'" class="td-child"  [routerLink]="['/company-profile']" [queryParams]="{companyId:item.companyId}">
                  Debt Management</td>
                <!-- <td *ngIf="item !== -1 && item?.companyType == 'P'"
                  [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'" class="td-child">
                  Probate</td> -->
                <td *ngIf="item !== -1" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'"
                  class="td-child"  [routerLink]="['/company-profile']" [queryParams]="{companyId:item.companyId}">
                  {{item.noOfUser}}</td>
                <td *ngIf="item !== -1" [ngClass]="item.status == 'Active' ? 'td-child' : 'td-child inactive'"
                  class="td-child"  [routerLink]="['/company-profile']" [queryParams]="{companyId:item.companyId}">
                  {{item.status}}</td>
                <td *ngIf="item !== -1" class="td-childo padding-right-0">
                  <div class="dropdown dropleft" (click)="openmenu()">
                    <a class="btn-new-b dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="assets\dsms-assets\dot_n.svg" alt="image not found" />
                    </a>
                    <div class="dropdown-menu-new dropdown-menu" id="padding-menu" aria-labelledby="dropdownMenuButton">
                      <div class="dropdown-item" *ngIf="item.status == 'Active'"
                        (click)="ChangeStatus(0, item.companyId)">Deactivate</div>
                      <div class="dropdown-item" *ngIf="item.status == 'Inactive'"
                        (click)="ChangeStatus(1, item.companyId)">Activate</div>
                      <div class="dropdown-item" data-toggle="modal" data-target="#deletemodal"
                        (click)="DeleteCompany('show',item.companyId)">Delete</div>
                      <!-- <div class="dropdown-item" [routerLink]="['/invoices']"
                        [queryParams]="{clientId: item.companyId}">
                        View Invoices</div> -->
                      <div class="dropdown-item"
                        [routerLink]="['/user-list','company', encryptClientId(item.companyId.toString())]">View Users
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="IsDataFound">
              <tr>
                <td colspan="5">
                  <div class="texttable">No records found</div>
                </td>
              </tr>
            </tbody>
            <ng-template #NoFiltered>
              <td colspan="5">
                <div class="texttable">No records found</div>
              </td>
            </ng-template>

            <tbody *ngIf="loader" class="text-center">
              <tr>
                <td colspan="5">
                  <div id="loader">
                    <div class="text-center">
                      <div class="spinner-border" role="status"></div>
                      <div class="textloader">Please wait while we are gathering data …</div>
                    </div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <ng-template #adddetailsmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div *ngIf="IsAddModal" class="modal-title" id="modal-basic-title">Add the new company details</div>
            <div *ngIf="!IsAddModal" class="modal-title" id="modal-basic-title">Edit company details</div>
            <button type="button" class="close close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="UserForm">
              <div class="modal-title texthead" id="modal-basic-title">Company Info</div>
              <div class="d-flex">
                <div class="form-group">
                  <select class="form-control" id="input-box" name="CompanyType" formControlName="CompanyType"
                    [ngClass]="{ 'is-invalid': f.CompanyType.touched && f.CompanyType.errors }">
                    <option value="" [disabled]="true" hidden selected>Company Type <sup>*</sup></option>
                    <option value="D"> Debt Management</option>
                    <option value="P"> Probate</option>
                  </select>
                  <div *ngIf="f.CompanyType.errors" class="invalid-feedback">
                    <div *ngIf="f.CompanyType.errors.required">Please select Company Type</div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group">
                  <input type="text" id="input-box" (keydown)="btnEnable()" placeholder="Company Name*"
                    value="Company Name" class="form-control" autocomplete="Name-off"
                    (focusout)="CheckCompany('Company')" (focus)="IsCompanyExist = false"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39 || event.charCode == 45"
                    formControlName="Name" [ngClass]="{ 'is-invalid': f.Name.touched && f.Name.errors }" minlength="2"
                    maxlength="30" />
                  <div *ngIf="f.Name.errors" class="invalid-feedback">
                    <div *ngIf="f.Name.errors.required && !IsCompanyExist">Please enter company name</div>
                    <div *ngIf="IsCompanyExist">Please try other company name,
                      its already added</div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group">
                  <input type="text" id="input-box" (keydown)="btnEnable()" placeholder="Company Number*"
                    value="Company Number" class="form-control" autocomplete="Name-off"
                    (focusout)="CheckCompany('Number')"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39 || event.charCode == 45 || event.charCode >= 48 && event.charCode <= 57"
                    formControlName="Number" [ngClass]="{ 'is-invalid': f.Number.touched && f.Number.errors }"
                    minlength="3" maxlength="30" />
                  <div *ngIf="f.Number.errors" class="invalid-feedback">
                    <div *ngIf="f.Number.errors.required">Please enter company number</div>
                    <div *ngIf="(f.Number.errors.minlength)">Please enter atleast 3 letters/digits for company number
                    </div>
                    <div *ngIf="IsCNumberExist">Please try other company number,
                      its already added</div>
                  </div>
                </div>
              </div>
              <div class="d-flex" id="form-container">
                <div class="input-group">
                  <div class="form-group d-flex">
                    <input type="text" id="form-email" class="form-control" placeholder="Enter Postcode" autocomplete="off" maxlength="8">
                    <div class="input-group-append">
                      <button id="btn-startsaving" class="btn btn-txt" type="submit">Get Address</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="form-group">
                  <input type="text" id="input-box" (keydown)="btnEnable()" placeholder="Company Subdomain*"
                    value="Company Subdomain" class="form-control" autocomplete="Name-off"
                    pattern="^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$" minlength="3" maxlength="30"
                    (focusout)="CheckSubdomain()" (focus)="SubdomainExistApiSuccess = false" formControlName="SubDomain"
                    [ngClass]="{ 'is-invalid': f.SubDomain.touched && f.SubDomain.errors }" />
                  <div *ngIf="f.SubDomain.errors" class="invalid-feedback">
                    <div *ngIf="f.SubDomain.errors.required">Please enter company subdomain</div>
                    <div *ngIf="f.SubDomain.errors?.pattern && !f.SubDomain.errors?.minlength">Please enter valid
                      company subdomain in one word</div>
                    <div *ngIf=" f.SubDomain.errors?.minlength">Company subdomain should be atleast of 3 letters</div>
                    <div *ngIf="IsSubdomainExist && !f.SubDomain.errors?.pattern">Please try other company subdomain,
                      its already taken</div>
                  </div>
                </div>
              </div>
              <div class="modal-title texthead" id="modal-basic-title">Contact Info</div>
              <div class="d-flex">
                <div class="form-group">
                  <select class="form-control" (change)="btnEnable()" id="tittle-css" name="title"
                    formControlName="Title" [ngClass]="{ 'is-invalid': f.Title.touched && f.Title.value =='' }">
                    <option value="" [disabled]="true" selected hidden>Title <sup>*</sup></option>
                    <option value="Mr"> Mr</option>
                    <option value="Mrs"> Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Sir">Sir</option>
                    <option value="Dr">Dr</option>
                    <option value="Rev">Rev</option>
                    <option value="Master">Master</option>
                    <option value="Professor">Professor</option>
                    <option value="Other">Other</option>
                  </select>
                  <div *ngIf="f.Title.touched && f.Title.value ==''" class="invalid-feedback"> Please select title</div>
                </div>
                <div class="form-group">
                  <input type="text" id="name-first" placeholder="First Name*" value="FName"
                    class="form-control margin-left-20" autocomplete="FName-off" (focusout)="CheckName($event,'fname')"
                    (keydown)="btnEnable()"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39 || event.charCode == 45"
                    formControlName="FName" [ngClass]="{ 'is-invalid': f.FName.touched && f.FName.errors }"
                    minlength="2" maxlength="30" (focus)="FNErrmsg=false" (focus)="FNInvalidmsg=false" />
                  <div *ngIf="f.FName.errors" class="invalid-feedback newmargin">
                    <div *ngIf="f.FName.errors.required || FNErrmsg">Please enter first name</div>
                    <div *ngIf="(f.FName.errors.minlength  || FNInvalidmsg) && !FNErrmsg">Please enter a valid first
                      name</div>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="form-group">
                  <input type="text" placeholder="Last Name*" id="input-box" class="form-control" required
                    formControlName="LName" autocomplete="LName-off" (focusout)="CheckName($event,'lname')"
                    (keydown)="btnEnable()" [ngClass]="{ 'is-invalid': f.LName.touched && f.LName.errors }"
                    minlength="2" maxlength="30" (focus)="LNErrmsg=false" (focus)="LNInvalidmsg=false"
                    onkeypress="if(this.value.length==30) return false; return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122  || event.charCode == 32 || event.charCode == 39  || event.charCode == 45">
                  <div *ngIf="f.LName.errors" class="invalid-feedback">
                    <div *ngIf="f.LName.errors.required || LNErrmsg">Please enter last name</div>
                    <div *ngIf="(f.LName.errors.minlength || LNInvalidmsg) !LNErrmsg">Please enter a valid last name
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group">
                  <input type="email" placeholder="Email*" id="input-box" formControlName="Email" [(ngModel)]="EmailId"
                    class="form-control" [ngClass]="{ 'is-invalid': (f.Email.touched && f.Email.errors)||EmailCheck }"
                    (focus)="EmailCheck = false" />
                  <div *ngIf="f.Email.touched && f.Email.errors" class="invalid-feedback" data=f.Email>
                    <div *ngIf="f.Email.errors.required">Email is required</div>
                    <div *ngIf="f.Email.errors.email ||(!f.Email.errors.required && f.Email.errors.email==null)">Please
                      enter a valid email address</div>
                  </div>
                  <div class="invalid-feedback">
                    <div *ngIf="EmailCheck">This email address is already in use</div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group">
                  <input type="text" id="input-box" placeholder="Phone Number*" value="Phone Number"
                    class="form-control" autocomplete="Number-off" formControlName="PhoneNumber"
                    [ngClass]="{ 'is-invalid': (IsMobileNoRequired || IsMobileNoNotValid) || (f.PhoneNumber.touched && f.PhoneNumber.errors) }"
                    maxlength="30" [(ngModel)]="MobileNo" (keyup)="checkMobileNo(MobileNo)" (keydown)="btnEnable()"
                    (focusout)="checkMob(MobileNo)" (focus)="IsMobileNoNotValid=false"
                    (focus)="IsMobileNoRequired=false"
                    onkeypress="if(this.value.length==11) return false;return event.charCode >= 48 && event.charCode <= 57" />
                  <div *ngIf="f.PhoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.PhoneNumber.errors.required && !IsMobileNoRequired">Please enter phone number</div>

                  </div>
                  <div class="text-danger" id="colorcode">
                    <div *ngIf="IsMobileNoNotValid">
                      Please enter a valid phone no starting with 07</div>
                    <div *ngIf="IsMobileNoRequired">
                      Please enter phone number</div>
                  </div>
                </div>
              </div>
              <div *ngIf="IsAddModal"><label class="container-01"> <span class="defaultuser-txt">Create as default
                    user</span>
                  <input type="checkbox" formControlName="checkbox" [(ngModel)]="checkbox1" [checked]="true"> <span
                    class="checkmark"></span> </label>
              </div>
            </form>
            <div class="btn-box">
              <button *ngIf="IsAddModal" class="btn btn-submit-modal" type="submit"
                [disabled]="UserForm.invalid || IsMobileNoRequired || IsMobileNoNotValid || requiredMsg || EmailMessage || !SubdomainExistApiSuccess"
                (click)="onSubmit();">
                <div class="container" *ngIf="!IsCompanyAdded">
                  <div id="custom-spinner-border" class="spinner-border" role="status"></div>

                </div> <span *ngIf="IsCompanyAdded">Add Company </span>
              </button>
              <button *ngIf="!IsAddModal" class="btn btn-submit-modal" type="submit"
                [disabled]="isShow || UserForm.invalid || IsMobileNoRequired || IsMobileNoNotValid || requiredMsg || EmailMessage"
                isShow=true; (click)="onSubmit();">Update Details</button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #viewdetailsmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div class="modal-title" id="modal-basic-title">View Company Details</div>
            <button type="button" class="close close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <!-- View Company details -->
          <div class="modal-body">
            <div class="texthead">Company Info</div>
            <div class="d-flex justify-content-between border-bn">
              <div class="text-name">Company Type </div>
              <div class="text-value" *ngIf="findedData?.companyType == 'P'">Probate</div>
              <div class="text-value" *ngIf="findedData?.companyType == 'D'">Debt Management</div>
            </div>
            <div class="d-flex justify-content-between border-bn">
              <div class="text-name">Company Name </div>
              <div class="text-value">{{findedData.name}}</div>
            </div>
            <div class="d-flex justify-content-between border-bn">
              <div class="text-name">Company Number</div>
              <div class="text-value">{{findedData.number}}</div>
            </div>
            <div class="d-flex justify-content-between border-bn">
              <div class="text-name">Company Domain</div>
              <div class="text-value clickdomain" (click)="ClickDomain()">https://{{findedData.subDomain}}.{{domainUrl}}
              </div>
            </div>
            <div class="boxpadding">
              <div class="texthead">Contact Info</div>

              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Title</div>
                <div class="text-value">{{findedData.title}} </div>
              </div>

              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">First Name</div>
                <div class="text-value">{{findedData.fName}} </div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Last Name</div>
                <div class="text-value">{{findedData.lName}}</div>
              </div>

              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Email</div>
                <div class="text-value">{{findedData.email}}</div>
              </div>
              <div class="d-flex justify-content-between border-bn">
                <div class="text-name">Phone Number </div>
                <div class="text-value">{{findedData.phoneNumber}}</div>
              </div>
            </div>

            <div class="btn-box">
              <button class="btn btn-submit-modal" type="submit"
                (click)="open(adddetailsmodal,'Edit',findedData.clientId);modal.dismiss('Cross click')">Edit
                Details</button>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- <ng-template #Pricemodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div class="modal-title" id="modal-basic-title">Update Pricing</div>
            <button type="button" class="close close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          
          <div class="modal-body">
            <div class="input-group" *ngIf="Type != '' && Type != null">
              <input list="browsers" name="PriceSearchClient" autocomplete="off" #client="ngModel" type="text"
                class="form-control new-changes select-css" placeholder="Select" [(ngModel)]="ClientName" required
                (focus)="isCompanyList = true" (focusout)="FocusoutClientValidation(ClientName)">
              <div [ngClass]="isCompanyList?'show':'hide'">
                <ul class="drplist">
                  <li *ngFor="let client of AllClient| filterclient :ClientName"
                    (mousedown)="SelectClient(client, 'Pricing')">
                    {{client.name}}
                  </li>
                </ul>
              </div>
            </div>
            <form [formGroup]="PriceForm">
              <select class="form-select menu-select" type="text" [attr.disabled]="state ? '' : null"
                aria-label=".form-select" *ngIf="Type == '' || Type == null">
                <option selected>{{findedData?.name}}</option>
              </select>
              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Price per Asset Search
                </div>
                <div class="text-value">
                  <div class="input-group-prepend d-flex">
                    <div class="input-group-text"
                      [ngClass]="CompanyType=='D'? 'input-group-text disabledtrue' : 'input-group-text'"
                      [attr.disabled]="CompanyType=='D'? true : null">£</div>
                    <input type="text" id="input-box" name="PartialPrice" formControlName="PartialPrice"
                      class="form-control box-s" autocomplete="off" twoDigitDecimaNumber
                      (focusout)="convertTwoDecimalPrice($event)" (keypress)="keyPressDecimals($event)"
                      [attr.disabled]="CompanyType=='D'? true : null" />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Price per Credit Search
                </div>
                <div class="text-value">
                  <div class="input-group-prepend d-flex">
                    <div class="input-group-text">£</div>
                    <input type="text" id="input-box" name="FullPrice" formControlName="FullPrice"
                      class="form-control box-s" autocomplete="off" twoDigitDecimaNumber
                      (focusout)="convertTwoDecimalPrice($event)" (keypress)="keyPressDecimals($event)" />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Price per Open Banking Credit
                </div>
                <div class="text-value">
                  <div class="input-group-prepend d-flex">
                    <div class="input-group-text"
                      [ngClass]="CompanyType=='P'? 'input-group-text disabledtrue' : 'input-group-text'"
                      [attr.disabled]="CompanyType=='P'? true : null">£</div>
                    <input type="text" id="input-box" name="BankConnectionsPrice" formControlName="BankConnectionsPrice"
                      class="form-control box-s" autocomplete="off" twoDigitDecimaNumber
                      (focusout)="convertTwoDecimalPrice($event)" (keypress)="keyPressDecimals($event)"
                      [attr.disabled]="CompanyType=='P'? true : null" />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Price per SMS Credit
                </div>
                <div class="text-value">
                  <div class="input-group-prepend d-flex">
                    <div class="input-group-text"
                      [ngClass]="CompanyType=='true'? 'input-group-text' : 'input-group-text disabledtrue'"
                      [attr.disabled]="true">£</div>
                    <input type="text" id="input-box" name="SMSPrice" formControlName="SMSPrice"
                      class="form-control  box-s" autocomplete="off" twoDigitDecimaNumber
                      (focusout)="convertTwoDecimalPrice($event)" (keypress)="keyPressDecimals($event)"
                      [attr.disabled]="true" />
                  </div>
                </div>
              </div>
            </form>

            <div class="Note-text">*The values must be between 0 to 9999</div>
            
            <div class="d-flex justify-content-between">
              <button class="btn btn-submit-bn" type="submit" (click)="ResetPricing(findedData.clientId)">Reset</button>
              <button class="btn btn-submit" id="vsub" type="submit" [disabled]="!IsClientSelected || btnDisable"
                (click)="UpdatePricing(findedData?.clientId);modal.dismiss('Cross click')">Update</button>
            </div>
          </div>
        </div>
      </ng-template> -->

      <!-- <ng-template #Creditmodal let-modal>
        <div class="modal-content-0">
          <div class="modal-header">
            <div class="modal-title" id="modal-basic-title">Add Credits</div>
            <button type="button" class="close close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <img class="img-fluid" src="../../../assets/images/close-btn.svg" alt="image">
            </button>
          </div>
          <div class="modal-body">           
            <div class="input-group" *ngIf="Type != '' && Type != null">
              <input list="browsers" name="PriceSearchClient" autocomplete="off" #client="ngModel" type="text"
                class="form-control new-changes select-css" placeholder="Start typing company name ..."
                [(ngModel)]="ClientName" required (focus)="isCompanyList = true"
                (focusout)="FocusoutClientValidation(ClientName)">
              <div [ngClass]="isCompanyList?'show':'hide'">
                <ul class="drplist">
                  <li *ngFor="let client of AllClient| filterclient :ClientName"
                    (mousedown)="SelectClient(client, 'Credits')">
                    {{client.name}}
                  </li>
                </ul>
              </div>
            </div>
            <select class="form-select menu-select" [attr.disabled]="state ? '' : null" aria-label=".form-select"
              *ngIf="Type == '' || Type == null">
              <option selected>{{findedData.name}}</option>
            </select>

            <form [formGroup]="CreditForm">
              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Number of Asset Searches
                </div>
                <div class="text-value d-flex">
                  {{creditList.PartialCredit}} + &nbsp;
                  <input type="number" formControlName="PartialCredit" name="PartialCredit" min="0" max="9999"
                    autocomplete="off" id="input-box" class="form-control v-width"
                    oninput="javascript: if (this.value >= 10000){this.value = 9999;}" (focus)="keyPressNumbers($event)"
                    (keydown)="restrictZero($event)" (keyup)="ValidateCreditForm($event)"
                    (change)="ValidateCreditForm($event)" (keypress)="keyPressNumbers($event)"
                    (focusout)="onFocusOutEvent($event)" [attr.disabled]="CompanyType=='D'? true : null" />
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Number of Credit Searches
                </div>
                <div class="text-value d-flex">
                  {{creditList.FullCredit}} + &nbsp;
                  <input type="number" formControlName="FullCredit" name="FullCredit" min="0" max="9999"
                    autocomplete="off" id="input-box" class="form-control v-width"
                    oninput="javascript: if (this.value >= 10000){this.value = 9999;}" (focus)="keyPressNumbers($event)"
                    (keydown)="restrictZero($event)" (keyup)="ValidateCreditForm($event)"
                    (change)="ValidateCreditForm($event)" (keypress)="keyPressNumbers($event)"
                    (focusout)="onFocusOutEvent($event)" />
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Number of Open Banking Credits
                </div>
                <div class="text-value d-flex">
                  {{creditList.BankCredit}} + &nbsp;
                  <input type="number" formControlName="BankConnectionsCredit" name="BankConnectionsCredit" min="0"
                    max="9999" autocomplete="off" id="input-box" class="form-control v-width"
                    oninput="javascript: if (this.value >= 10000){this.value = 9999;}" (focus)="keyPressNumbers($event)"
                    (keydown)="restrictZero($event)" (keyup)="ValidateCreditForm($event)"
                    (change)="ValidateCreditForm($event)" (keypress)="keyPressNumbers($event)"
                    (focusout)="onFocusOutEvent($event)" [attr.disabled]="CompanyType=='P'? true : null" />
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="text-value-0">
                  Number of SMS Credits
                </div>
                <div class="text-value d-flex">
                  {{creditList.SMSCredit}} + &nbsp;
                  <input type="number" formControlName="SMSCredit" name="SMSCredit" min="0" max="9999"
                    autocomplete="off" id="input-box" class="form-control v-width"
                    oninput="javascript: if (this.value >= 10000){this.value = 9999;}" (focus)="keyPressNumbers($event)"
                    (keydown)="restrictZero($event)" (keyup)="ValidateCreditForm($event)"
                    (change)="ValidateCreditForm($event)" (keypress)="keyPressNumbers($event)"
                    (focusout)="onFocusOutEvent($event)" [attr.disabled]="CompanyType=='P'? true : null" />
                </div>
              </div>
            </form>
            <div class="Note-text">*The values must be between 0 to 9999</div><br>
            <div class="d-flex justify-content-between">
              <button class="btn btn-submit-bn" type="submit" (click)="ResetCredit()">Reset</button>
              <button class="btn btn-submit vsubn" type="submit" [disabled]="!IsClientSelected || btnDisable"
                (click)="UpdateCredit(findedData.clientId);modal.dismiss('Cross click');">Update</button>
            </div>
          </div>
        </div>
      </ng-template> -->
      <!-- Modal -->
      <!-- <div class="modal fade" id="creditmodal" tabindex="-1" role="dialog" aria-labelledby="insidemodalTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" id="modal-inside">
            <div class="modal-body m-p">
              <div class="text-v">Are you sure you want to add the credits?</div>
              <div class="d-flex justify-content-between" id="margin-y">
                <button type="button" class="btn btn-no" data-dismiss="modal"
                  (click)="UpdateConfirm('credit','Notupdate')">No</button>
                <button type="button" [disabled]="IsDisable" class="btn btn-submit btn0-w"
                  (click)="UpdateConfirm('credit','update')">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Modal -->
      <div class="modal fade" id="deletemodal" tabindex="-1" role="dialog" aria-labelledby="insidemodalTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" id="modal-inside">
            <div class="modal-body">
              <div class="text-center">
                <img class="img-fluid" src="../../../assets/images/delete-icon.svg" alt="image">
              </div>
              <div class="delete-header">Delete {{findedData?.email}}?</div>
              <div class="text-delete">Deleting company <strong>{{findedData?.name}}</strong> will remove all data
                related to this
                user from the system.</div>
              <div class="text-delete marginx">Still want to proceed?</div>
              <div class="d-flex" id="margin-y">
                <button type="button" class="btn btn-no" data-dismiss="modal"
                  (click)="DeleteCompany('hide', findedData.companyId)">No</button>
                <button type="button" class="btn btn-submit btn0-w"
                  (click)="DeleteCompany('delete', findedData.companyId)">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pricing Modal -->
      <!-- <div class="modal fade" id="Pricingmodal" tabindex="-1" role="dialog" aria-labelledby="insidemodalTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" id="modal-inside">
            <div class="modal-body">
              <div class="text-v">Are you sure you want to update the pricing?</div>
              <div class="d-flex" id="margin-y">
                <button type="button" class="btn btn-no" data-dismiss="modal"
                  (click)="UpdateConfirm('Pricing','Notupdate')">No</button>
                <button type="button" class="btn btn-submit btn0-w"
                  (click)="UpdateConfirm('Pricing','update')">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>