import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRights, userTypeEnum } from '../../Extras/UserRights/userRights';
import { DashboardRoute} from '../../Models/dmsModel';

@Component({
  selector: 'app-nav-superadmin',
  templateUrl: './nav-superadmin.component.html',
  styleUrls: ['./nav-superadmin.component.css']
})
export class NavSuperadminComponent implements OnInit {
  year: number;
  userFullName: string;
  phone: string;
  email: string;
  IsShowDashboardLink:boolean=true;
  href:any
  DashboardRoute=DashboardRoute;
  UserRandomId: string;
  ShowSearchLink:boolean=true;

  @Output("NewSearchClick") SearchClickEvent: EventEmitter<any> = new EventEmitter();
  constructor(private route: Router, private modalService: NgbModal, private userrights: UserRights) {

    this.userFullName = sessionStorage.getItem('name');
    this.phone = sessionStorage.getItem('phone');
    this.email = sessionStorage.getItem('email');
    this.UserRandomId = sessionStorage.getItem('UserRandomId');
  }
  ngOnInit() {
    this.year = new Date().getFullYear();
    this.ShowDashboardLink();

  }
  Refresh(){
    if(this.route.url == '/dashboard')
    {
      window.location.reload();
      if (sessionStorage.getItem("customerId") !== null) {
        sessionStorage.removeItem("customerId");          
        sessionStorage.removeItem("customerEmail");
        sessionStorage.removeItem("customerFName");
        sessionStorage.removeItem("customerPhone");
      }
    }
  }
  NewSearchClick() {
    this.SearchClickEvent.emit(1);
  }
  PreviousSearchClick() {
    this.SearchClickEvent.emit(2);
  }
  Logout() {
    console.log("Tocheck login redirection: nav-superadmin.component");
    this.route.navigate(['/login']);
    sessionStorage.clear();
    localStorage.clear();
  }

  MoveToDashboard() {
    
    let userTypeId = this.userrights.GetUserType();
    if (userTypeId == userTypeEnum.agent) {
      this.route.navigate([DashboardRoute.AgentDashboard]);
    }
    if (userTypeId == userTypeEnum.companyAdmin) {
      this.route.navigate([DashboardRoute.ClientDashboard]);
    }
    if (userTypeId == userTypeEnum.superAdmin) {
      this.route.navigate([DashboardRoute.SuperDashboard]);
    }
    if (userTypeId == userTypeEnum.systemAdmin) {
      this.route.navigate([DashboardRoute.systemDashboard]);
    }
  }

  ShowDashboardLink() {
    let userTypeId = this.userrights.GetUserType();

    if (userTypeId == userTypeEnum.superAdmin || userTypeId==userTypeEnum.systemAdmin) {
      this.ShowSearchLink=false;
    }
    if (userTypeId == userTypeEnum.agent) {
      this.IsShowDashboardLink=false;
    }
    else 
    {
      this.href = this.route.url;
      this.IsShowDashboardLink=true;
      if(this.href == '/dashboard'){
        this.href = '';
      }
    }
    
  }

}
