import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomerDetails, AddAccount, CustomerValidationModel, AddUser, AddClient, PricingList, CreditList, BuyCredits, UserPasswordDTO, CustomerActivityModel, CardDetail, DownloadReport, UserAddressModel, CreditorsReport, UserStatusModel, EmployeeStatusModel } from '../Models/dmsModel';
import { CryptographyService } from './cryptography.service';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { CompanyDeleteModel, CompanyDetailModel, CompanyStatusModel } from '../Models/companyModel';

@Injectable({
  providedIn: 'root'
})
export class DmsasService {

  readonly rootUrl = environment.apiUrl;
  readonly ookiUrl = environment.ookiApiUrl;
  idToken = null;

  AvailableCredits = new Subject<any>();
  //IsCustomerDetails = new Subject<boolean>();
  CustomerId = new Subject<number>();
  IsBankAccountAdded = new Subject<boolean>();
  IsCreditReportAvailable = new Subject<boolean>();
  IsRecentSearchLoaded = new Subject<boolean>();
  IsTimerOpenPopup = new Subject<any>();

  constructor(private _http: HttpClient,
    private crypto_service: CryptographyService,
    private router: Router,
    private route: ActivatedRoute) {

  }

  ClearQueryParams() {
    this.router.navigate([], { relativeTo: this.route, queryParams: {} });
  }
  getDomainName(token: string): string {
    if (token != null && token != '') {
      let decoded = jwt_decode(token) as any;
      if (decoded) {
        if (decoded.clientinfo.split('|')[0] == 0) {
          return 'admin';
        }
        return decoded.clientinfo.split('|')[1];
      }
      else {
        return '';
      }
    }
    else
      return '';
  }

  Login(payload: any) {

    let user_encrypted = Object.assign({}, payload);
    user_encrypted.Password = this.crypto_service.encrypt(payload.Password);
    return this._http.post(this.rootUrl + '/Login', user_encrypted);
  }

  // getRecentSearch(fullSearch: any) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.get(this.rootUrl + '/GetCustomerSearchHistory?fullSearch=' + fullSearch, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // GetCustomerData(customerId: number) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.get(this.rootUrl + '/GetCustomerData?customerId=' + customerId, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // AddCustomer(userDetail: UserAddressModel) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/AddCustomer', userDetail, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  getAddress(Postcode): Observable<any> {
    let random = Math.random().toString().replace(".", "");
    return this._http.get<any>(this.ookiUrl + '/public-auth/GetAddress/' + encodeURIComponent(Postcode) + '?key=' + random);
  }

  // SendEmailToAddAccount(customerDetails: CustomerDetails) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/AddAccountRequestByEmail', customerDetails, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // AddNewAccounts(addaccountmodel: AddAccount) {
  //   return this._http.post<any>(this.rootUrl + '/AddAccount', addaccountmodel);
  // }

  // ValidateCustomerDetails(_custValidModel: CustomerValidationModel) {
  //   return this._http.post<any>(this.rootUrl + '/validatecustomerdetails', _custValidModel);
  // }

  SendSMS(customerDetails: CustomerDetails) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/SendSms', customerDetails, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  // AddedAccount(CustomerId: any) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/IsAccountAdded', { CustomerId }, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // GetBankAccountPdfReport(CustomerId: any) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/AccountStatement', { CustomerId }, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }
  // DownloadBankAccountPdfReport(report: DownloadReport) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/AccountStatement', report, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }
  getUser() {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/user', { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  getClient() {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/company', { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  getUserType() {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/GetUserType', { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  AddUsers(addUsers: AddUser) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/User', addUsers, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  UpdateUsers(editUsers: AddUser) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/EditUser', editUsers, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  DeleteUsers(userId: any) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/DeleteUser', userId, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  AddClients(addClients: AddClient) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/company', addClients, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  // UpdatePricing(pricingList: PricingList) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/CreditRate', pricingList, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // UpdateCredits(creditList: CreditList) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/Credits', creditList, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }
  // getPricing(clientId: any) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.get(this.rootUrl + '/CreditRate?companyId=' + clientId, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }
  // getCredits(clientId: number) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.get(this.rootUrl + '/Credits?companyId=' + clientId, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }
  // MakePayment(buyCreditList: BuyCredits) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/CreditPayments', buyCreditList, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  getPaymentTransaction(companyId: number) {
    this.idToken = sessionStorage.getItem('token');
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('type', sessionStorage.getItem('type'));
    return this._http.get(this.rootUrl + '/PaymentTransactions?' + params, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  getStatistics() {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/statistics', { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  getCreditSummary(clientId: any) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/CreditSummary?companyId=' + clientId, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  getAvailableCredits() {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/AvailableCredits', { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  CheckUserExist(userName: string) {
    let user=encodeURIComponent(userName);
    return this._http.get(this.rootUrl + '/CheckUserExist?userName=' + user);
  }

  ActivateUserAccount(userPwdModel: UserPasswordDTO) {
    userPwdModel.Password = this.crypto_service.encrypt(userPwdModel.Password);
    return this._http.post(this.rootUrl + '/ActivateUserAccount', userPwdModel);
  }

  // CustomerActivityLog(activityModel: CustomerActivityModel) {
  //   return this._http.post(this.rootUrl + '/customeractivitylog', activityModel);
  // }
  // GetTransactionList(customerId: any) {
  //   return this._http.post(this.rootUrl + '/AccountTransactionList', { customerId });
  // }

  // getSavedCard() {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.get(this.rootUrl + '/UserCards', { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // SaveUserCard(cardDetail: CardDetail) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/ProcessPayment', cardDetail, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  public downloadInvoicePdf(transactionId: string): Observable<any> {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/downloaddocfile/Invoice/' + transactionId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.idToken }) });
  }

  SubdomainExist(Subdomain: string) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/IsSubdomainExist', { Subdomain }, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  // GetCreditorsReport(creditReport: CreditorsReport) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/CreditorsReport', creditReport, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // downloadCreditReport(customerId: number): Observable<any> {
  //   this.idToken = sessionStorage.getItem('token');
  //   const params = new HttpParams().set('customerId', customerId);
  //   return this._http.get(this.rootUrl + '/CreditReportpdf?' + params, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.idToken }) });
  // }

  // GetCreditsReportByCustomer(creditReport: CreditorsReport) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/GetCreditReport', creditReport, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  // GetAddedBankAccounts(CustomerId: any) {
  //   this.idToken = sessionStorage.getItem('token');
  //   return this._http.post(this.rootUrl + '/GetAddedBankAccounts', { CustomerId }, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  // }

  DeleteCompany(companyDelete: CompanyDeleteModel) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/removecompany', companyDelete, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  UpdateCompanyCondition(companyStatus: CompanyStatusModel) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/changecompanycondition', companyStatus, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  UpdateUserStatus(userStatus: UserStatusModel) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/updateuserstatus', userStatus, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  GetAllEmployees() {
    this.idToken = sessionStorage.getItem('token');
    return this._http.get(this.rootUrl + '/GetAllEmployees' , { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  UpdateEmployeeStatus(employeeStatus: EmployeeStatusModel) {
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/updateemployeestatus', employeeStatus, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  getCompanyDetails(companyId: number) {
    this.idToken = sessionStorage.getItem('token');
    const params = new HttpParams().set('companyId', companyId);
    return this._http.get(this.rootUrl + '/companydetail?'  + params, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }

  saveCompanyDetails(companyDetail: CompanyDetailModel){
    this.idToken = sessionStorage.getItem('token');
    return this._http.post(this.rootUrl + '/savecompanydetail', companyDetail, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + this.idToken }) });
  }
  
}
