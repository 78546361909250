import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterdata'
})
export class FilterdataPipe implements PipeTransform {

  transform(cusomerList: any[], filter: string): any {
    if (!filter) {
      return cusomerList;
    }
    filter = filter.replace(/^((.)|\s+(.))/g, c => c.toLowerCase());
    let result = cusomerList.filter(item => 
      item?.fName?.toLowerCase().includes(filter) ||
    item?.lName?.toLowerCase().includes(filter.toLowerCase()) ||
    (item?.title +' '+ item?.fName +' '+ item?.lName).toLowerCase().includes(filter) ||
    item?.email?.includes(filter.toLowerCase()) ||
    item?.phone?.includes(filter.toLowerCase()) ||
    item?.number?.includes(filter.toLowerCase())  ||
    item?.name?.toLowerCase().includes(filter.toLowerCase()) )
    if(result.length === 0) {
      return [-1];
    }
    return result; 
  }

}
