import { InvoiceClientComponent } from './Components/invoice-client/invoice-client/invoice-client.component';
import { AdminClientListComponent } from './Components/admin-client-list/admin-client-list.component';
import { AdminUserListComponent } from 'src/app/Components/admin-user-list/admin-user-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Extras/AuthGuard/auth.guard';
import { AgentadminComponent } from './Pages/agentadmin/agentadmin.component';
import { LoginComponent } from './Pages/login/login/login.component';
import { InvoiceDebtsComponent } from './Pages/invoice-debts/invoice-debts.component';
import { ErrorPageComponent } from './Components/error-page/error-page.component';
import { LoginFailedComponent } from './Pages/login-failed/login-failed.component';
//import { BulkUploadComponent } from './Pages/bulk-upload/bulk-upload.component';
import { SearchEmployeeComponent } from './Components/search-employee/search-employee.component';
import { AgentdashboardComponent } from './Components/agentdashboard/agentdashboard.component';
import { ActivateUserPasswordComponent } from './Pages/activate-user-password/activate-user-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'error-page', component: ErrorPageComponent },
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: '*', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login-failed', component: LoginFailedComponent },

  { path: 'import-employees', loadChildren: () => import('./Pages/bulk-upload/bulk-upload.module').then(m => m.bulkuploadModule), canActivate: [AuthGuard], data: { role: 'ADB' }  },
  { path: 'dashboard', loadChildren: () => import('./Pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard], data: { role: 'NCS' } },
  { path: 'superadmin', loadChildren: () => import('./Pages/superadmin/superadmin.module').then(m => m.SuperadminModule), canActivate: [AuthGuard], data: { role: 'ADB' } },
  { path: 'agentadmin', loadChildren: () => import('./Pages/agentadmin/agentadmin.module').then(m => m.AgentadminModule), canActivate: [AuthGuard], data: { role: 'CLD' } },
  { path: 'systemadmin-dashboard', loadChildren: () => import('./Pages/systemadmin-dashboard/systemadmin-dashboard.module').then(m => m.SystemadminDashboardModule), canActivate: [AuthGuard], data: { role: 'ADB' } },
  { path: 'company-profile', loadChildren: () => import('./Pages/company-profile/company-profile.module').then(m => m.CompanyprofileModule), canActivate: [AuthGuard], data: { role: 'NCS' } },
  { path: 'user-list', component: AdminUserListComponent, canActivate: [AuthGuard], data: { role: 'USRLST' } },
  { path: 'user-list/:type', component: AdminUserListComponent, canActivate: [AuthGuard], data: { role: 'USRLSTTYPE' } },
  { path: 'user-list/:type/:clientId', component: AdminUserListComponent, canActivate: [AuthGuard], data: { role: 'USRLSTTYPE' } },
  { path: 'company-dashboard', component: AgentdashboardComponent, canActivate: [AuthGuard], data: { role: 'CLD' } },
  { path: 'company-list', component: AdminClientListComponent, canActivate: [AuthGuard], data: { role: 'CMPLST' } },
  { path: 'invoices', component: InvoiceClientComponent, canActivate: [AuthGuard], data: { role: 'INVC' } },
  { path: 'search-employee', component: SearchEmployeeComponent, canActivate: [AuthGuard], data: { role: 'USRLST' } },
 // { path: 'import-employees', component: BulkUploadComponent, canActivate: [AuthGuard], data: { role: 'ADB' } },

  { path: 'agentadminNav', component: AgentadminComponent },
  { path: 'paymentinvoices', component: InvoiceDebtsComponent },  
  { path: 'activate-user-password', component: ActivateUserPasswordComponent },
  { path: '**', redirectTo: '/error-page' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
