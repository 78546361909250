import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { AddClient, PricingList, CreditList, ClientStatusEnum, AddUser } from '../../Models/dmsModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRights, userTypeEnum } from '../../Extras/UserRights/userRights';
import { CryptographyService } from 'src/app/Services/cryptography.service';
import { environment } from '../../../environments/environment';
import { CompanyDeleteModel, CompanyStatusModel } from '../../Models/companyModel';
declare let $: any;
@Component({
  selector: 'app-admin-client-list',
  templateUrl: './admin-client-list.component.html',
  styleUrls: ['./admin-client-list.component.css']
})
export class AdminClientListComponent implements OnInit {
  imgSrc = '../../../assets/ooki-assets/';
  UserForm: FormGroup;
  addClients = new AddClient();
  EmailMessage: boolean = false;
  requiredMsg: boolean = false;
  EmailId: any;
  FNErrmsg: boolean = false;
  LNErrmsg: boolean = false;
  RequiredDobMsg: boolean = false;
  submitted = false;
  searchText = '';
  searchClient = '';
  clientType: any;
  FNInvalidmsg: boolean = false;
  LNInvalidmsg: boolean = false;
  MobileNo: any;
  IsMobileNoRequired: boolean = false;
  IsMobileNoNotValid: boolean = false;
  IsAddModal: boolean = false;
  findedData: any
  loader: boolean = true;
  IsDataFound: boolean;
  //PriceForm: FormGroup;
  //CreditForm: FormGroup;
  pricingList = new PricingList();
  creditList = new CreditList();
  ExistedCredit: any = 0;
  ExistedPricing: any = 0;
  currentItem: string;
  CreditField: string;
  AllClient: any;
  ClientName: string;
  IsClientSelected: boolean = false;
  Type: string = '';
  UserTypes: string;
  superAdminBackBtn: any;
  systemAdminBackBtn: any;
  isShow: boolean = false;
  formcontrol: string;
  state = false;
  btnDisable: boolean = true;
  IsSubdomainExist: boolean = false;
  SubdomainExistApiSuccess: boolean = false;
  checkbox1: boolean = true;
  addUsers = new AddUser();
  IsCompanyExist: boolean = false;
  IsCNumberExist: boolean = false;
  patialName: string;
  InvalidChars = ["-", "+", "e", "."];
  IsDisable: boolean = false;
  EmailCheck = false;
  CompanyType: string;
  IsCompanyAdded: boolean = true;
  isCompanyList: boolean = false;

  readonly domainUrl = environment.domainUrl;
  @ViewChild('Pricemodal', { read: TemplateRef }) Pricemodal: TemplateRef<any>;
  @ViewChild('Creditmodal', { read: TemplateRef }) Creditmodal: TemplateRef<any>;

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,
    private _dmsService: DmsasService, private router: Router, private route: ActivatedRoute,
    private userrights: UserRights, private _cryptoService: CryptographyService) {

    let params = this.router.getCurrentNavigation().extras["state"] ? this.router.getCurrentNavigation().extras["state"] : ""
    this.Type = params['type']
  }

  ngOnInit(): void {
    if (this.Type == null || this.Type == '') { this.state = !this.state; }
    this.UserTypes = sessionStorage.getItem('type');
    let userTypeId = this.userrights.GetUserType();
    if (userTypeId == userTypeEnum.superAdmin) {
      this.superAdminBackBtn = true;
    }
    if (userTypeId == userTypeEnum.systemAdmin) {
      this.systemAdminBackBtn = true;
    }
    this.getClient();
    this.UserForm = this.formBuilder.group({
      CompanyType: ['', Validators.required],
      Name: ['', Validators.required],
      Number: ['', Validators.required],
      Title: ['', Validators.required],
      FName: ['', Validators.required],
      LName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      PhoneNumber: ['', Validators.required],
      SubDomain: ['', Validators.required],
      checkbox: []
    });

    // this.PriceForm = this.formBuilder.group({
    //   PartialPrice: ['0.00', Validators.required],
    //   FullPrice: ['0.00', Validators.required],
    //   BankConnectionsPrice: ['0.00', Validators.required],
    //   SMSPrice: ['0.00', Validators.required]
    // });

    // this.CreditForm = this.formBuilder.group({
    //   PartialCredit: [0, Validators.required],
    //   FullCredit: [0, Validators.required],
    //   BankConnectionsCredit: [0, Validators.required],
    //   SMSCredit: [0, Validators.required]
    // });

  }
  get f() {
    return this.UserForm.controls;
  }

  // get pf() {
  //   return this.PriceForm.controls;
  // }
  // get cf() {
  //   return this.CreditForm.controls;
  // }

  open(content: any, Modal: string, clientId: number) {
    this.EmailCheck = false;
    this.btnDisable = true;
    this.findedData = '';
    if (Modal == 'AddClient') {
      this.UserForm.controls['SubDomain'].enable();
      this.SubdomainExistApiSuccess = false;
      this.IsAddModal = true;
      this.MobileNo = this.EmailId = '';
      this.requiredMsg = this.EmailMessage = false;
      this.IsMobileNoRequired = this.IsMobileNoNotValid = false;

      this.UserForm.reset();
      this.UserForm.controls['Title'].setValue('');
      this.UserForm.controls['CompanyType'].setValue('');
      this.UserForm.controls['CompanyType'].enable();
    }
    else {
      this.IsAddModal = false;
      this.requiredMsg = this.EmailMessage = false;
      this.IsMobileNoRequired = this.IsMobileNoNotValid = false;

      this.UserForm.reset();
      this.EditModal(clientId)
    }

    this.modalService.open(content, { windowClass: 'custom-class' });
  }

  EditModal(clientId: number) {
    this.isShow = true;
    this.findedData = this.clientType.find(i => i.clientId === clientId);
    this.UserForm.controls['Name'].setValue(this.findedData.name);
    this.UserForm.controls['Number'].setValue(this.findedData.number);
    this.MobileNo = this.findedData.phoneNumber;
    this.UserForm.controls['Title'].setValue(this.findedData.title);
    this.UserForm.controls['FName'].setValue(this.findedData.fName);
    this.UserForm.controls['LName'].setValue(this.findedData.lName);
    this.UserForm.controls['Email'].setValue(this.findedData.email.toLowerCase());
    this.UserForm.controls['PhoneNumber'].setValue(this.findedData.phoneNumber);
    if (!this.IsAddModal) {
      this.UserForm.controls['SubDomain'].setValue(this.findedData.subDomain)
      this.UserForm.controls['SubDomain'].disable();
      this.UserForm.controls['CompanyType'].setValue(this.findedData.companyType)
      this.UserForm.controls['CompanyType'].disable();
    }
    else {
      this.UserForm.controls['SubDomain'].enable();
      this.UserForm.controls['SubDomain'].setValue(this.findedData.subDomain)
    }
    this.EmailId = this.findedData.email.toLowerCase();
  }

  SelectClient(client: any, fetchdata: string) {
    this.IsClientSelected = true;
    this.ClientName = client.name;
    this.CompanyType = client.companyType;
    this.isCompanyList = false;
    this.findedData = client;
  }

  getClient() {
    this._dmsService.getClient().subscribe((res: any) => {
      if (res?.result != null && res.result.length > 0) {
        this.loader = false;
        this.IsDataFound = false;
        this.clientType = res.result;

        // For default user if checkbox true while adding company        
        if ((this.checkbox1 && this.IsAddModal) && res.result[0].companyId > 0) {
          this.addUsers.CompanyId = res.result[0].companyId;
          this.addUsers.Email = this.addClients.Email;
          this.addUsers.FName = this.addClients.FName;
          this.addUsers.LName = this.addClients.LName;
          this.addUsers.Status = this.addClients.Status;
          this.addUsers.Title = this.addClients.Title;
          this.addUsers.UserTypeId = userTypeEnum.companyAdmin;
          this.addUsers.IsDefaultClientAdmin = true;

          this._dmsService.AddUsers(this.addUsers).subscribe((response: any) => {
            if (response != null && response?.status == "NewUser") {
              // Call getClient() again to display correct No. of Users
              this._dmsService.getClient().subscribe((res: any) => {
                this.clientType = res.result;
                this.IsCompanyAdded = true;
                this.modalService.dismissAll();
              }, (err: HttpErrorResponse) => {
                console.log('Catch exception: ' + err);
              });
            }
          })
        }
        else {
          this.IsCompanyAdded = true;
          this.modalService.dismissAll();
        }
      }
      else {
        this.IsCompanyAdded = true;
        this.modalService.dismissAll();
        this.loader = false;
        this.IsDataFound = true;
      }
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }

  CheckName(event: any, nametype) {
    event.target.value = (event.target.value.replace(/\s\s+/g, ' ')).trim();
    let Name = event.target.value;
    if (Name != '') {
      this.patialName = Name.split(' ');
    }
    if (nametype == 'fname') {
      if (Name == '') {
        this.UserForm.controls['FName'].setErrors({ 'incorrect': true });
        this.FNErrmsg = true
      }
      else {
        for (let value of this.patialName) {
          if (value.length < 2) {
            this.UserForm.controls['FName'].setErrors({ 'incorrect': true });
            this.FNInvalidmsg = true;
          }
        }
      }
    }
  }

  onSubmit() {
    this.EmailCheck = false;
    this.submitted = true;
    if (this.UserForm.invalid) {
      return;
    }
    else {
      this.IsCompanyAdded = false
      this.addClients = this.UserForm.value;
      if (this.findedData.clientId > 0) {
        this.addClients.ClientId = this.findedData.clientId
      }      
      this._dmsService.AddClients(this.addClients).subscribe((res: any) => {
        if (res.result?.isDuplicateMail == 1) {
          this.EmailCheck = true;
          this.IsCompanyAdded = true;
        }
        else {
          this.getClient();
        }
      });
    }
  }

  checkMobileNo(MobileNo) {
    let mobile = MobileNo;
    if (mobile == '' || mobile == undefined) {
      this.IsMobileNoRequired = false;
    }
    else if (mobile.search('(07)[0-9]{9}') == -1) {
      this.IsMobileNoNotValid = false;
    } else {
      this.UserForm.controls['PhoneNumber'].setValue(mobile);
      this.IsMobileNoRequired = false;
      this.IsMobileNoNotValid = false;
    }
  }

  checkMob(MobileNo) {
    if (MobileNo == '' || MobileNo == undefined) {
      this.IsMobileNoRequired = true;
      this.IsMobileNoNotValid = false;
    }
    else if (MobileNo.search('(07)[0-9]{9}') == -1) {
      this.IsMobileNoRequired = false;
      this.IsMobileNoNotValid = true;
    } else {
      this.UserForm.controls['PhoneNumber'].setValue(MobileNo);
      this.IsMobileNoRequired = false;
      this.IsMobileNoNotValid = false;
    }
  }

  ChangeStatus(PerformState: number, clientId: number) {
    const companyStatus: CompanyStatusModel = {
      CompanyId: clientId,
      Status: PerformState
    };

    this._dmsService.UpdateCompanyCondition(companyStatus).subscribe((res: any) => {
      this.getClient();
    });
  }

  // UpdatePricing(clientId: number) {
  //   this.btnDisable = true;
  //   $('#Pricingmodal').modal('show');
  //   this.pricingList.ClientId = clientId;
  //   this.pricingList.PartialPrice = Number(this.pf.PartialPrice.value);
  //   this.pricingList.FullPrice = Number(this.pf.FullPrice.value);
  //   this.pricingList.BankPrice = Number(this.pf.BankConnectionsPrice.value);
  //   this.pricingList.SMSPrice = Number(this.pf.SMSPrice.value);
  // }

  // UpdateCredit(clientId: number) {
  //   this.btnDisable = true;
  //   $('#creditmodal').modal('show');
  //   this.creditList.ClientId = clientId;
  //   this.creditList.PartialCredit = Number(this.cf.PartialCredit.value);
  //   this.creditList.FullCredit = Number(this.cf.FullCredit.value);
  //   this.creditList.BankCredit = Number(this.cf.BankConnectionsCredit.value);
  //   this.creditList.SMSCredit = Number(this.cf.SMSCredit.value);
  //   this.creditList.ClientFirstName = this.findedData.fName
  //   this.creditList.Email = this.findedData.email.toLowerCase();
  // }

  // FetchCredits(clientId: number) {
  //   this._dmsService.getCredits(clientId).subscribe((res => {
  //     if (res != null) {
  //       this.ExistedCredit = res;
  //       if (this.ExistedCredit.result != null) {
  //         this.creditList.PartialCredit = this.ExistedCredit.result[0].partialCredit
  //         this.creditList.FullCredit = this.ExistedCredit.result[0].fullCredit
  //         this.creditList.BankCredit = this.ExistedCredit.result[0].bankCredit
  //         this.creditList.SMSCredit = this.ExistedCredit.result[0].smsCredit
  //       }
  //       else
  //         this.creditList.PartialCredit = this.creditList.FullCredit = this.creditList.BankCredit = this.creditList.SMSCredit = 0;
  //     }
  //   }))
  // }

  // ResetCredit() {
  //   this.CreditForm.setValue({
  //     PartialCredit: 0,
  //     FullCredit: 0,
  //     BankConnectionsCredit: 0,
  //     SMSCredit: 0,
  //   });
  // }

  // ResetClientList() {
  //   this.creditList.PartialCredit = 0;
  //   this.creditList.FullCredit = 0;
  //   this.creditList.BankCredit = 0;
  //   this.creditList.SMSCredit = 0;
  // }

  // FetchPricing(clientId: number) {
  //   this._dmsService.getPricing(clientId).subscribe((res => {
  //     if (res != null) {
  //       this.ExistedPricing = res;
  //       if (this.ExistedPricing.result != null) {
  //         this.pricingList.PartialPrice = this.ExistedPricing.result[0].partialPrice.toFixed(2);
  //         this.pricingList.FullPrice = this.ExistedPricing.result[0].fullPrice.toFixed(2);
  //         this.pricingList.BankPrice = this.ExistedPricing.result[0].bankPrice.toFixed(2);
  //         this.pricingList.SMSPrice = this.ExistedPricing.result[0].smsPrice.toFixed(2);
  //         this.pf.PartialPrice.setValue(this.pricingList.PartialPrice);
  //         this.pf.FullPrice.setValue(this.pricingList.FullPrice);
  //         this.pf.BankConnectionsPrice.setValue(this.pricingList.BankPrice);
  //         this.pf.SMSPrice.setValue(this.pricingList.SMSPrice);
  //       }
  //       else {
  //         // this.PriceForm.setValue({
  //         //   PartialPrice: 0.00,
  //         //   FullPrice: 0.00,
  //         //   BankConnectionsPrice: 0.00,
  //         //   SMSPrice: 0.00,
  //         // });
  //       }
  //     }
  //   }))
  // }

  // ResetPricing(clientId: number) {
  //   this.FetchPricing(clientId)
  // }

  DeleteCompany(task: any, companyId: number) {
    this.findedData = this.clientType.find(i => i.companyId === companyId);
    if (task == 'show') {
      $('#deletemodal').modal('show');
    }
    else if (task == 'hide') {
      $('#deletemodal').modal('hide');
    }
    else if (task == 'delete') {
      $('#deletemodal').modal('hide');
      const companyDelete: CompanyDeleteModel = {
        CompanyId: companyId,
        IsActive: false
      };

      this._dmsService.DeleteCompany(companyDelete).subscribe((res => {
        this.getClient();
      }))
    }
  }

  // UpdateConfirm(modal: string, task: string) {
  //   if (modal == 'credit') {
  //     if (task == 'update') {
  //       this.IsDisable = true;
  //       this._dmsService.UpdateCredits(this.creditList).subscribe((res => {
  //         $('#creditmodal').modal('hide');
  //         this.IsDisable = false;
  //       }))
  //     }
  //     else {
  //       $('#creditmodal').modal('hide');
  //     }
  //   }
  //   else if (modal == 'Pricing') {
  //     if (task == 'update') {
  //       this._dmsService.UpdatePricing(this.pricingList).subscribe((res => {
  //         $('#Pricingmodal').modal('hide');
  //       }))
  //     }
  //     else {
  //       $('#Pricingmodal').modal('hide');
  //     }
  //   }
  // }

  openmenu() {
    this.Type = '';
    this.IsClientSelected = true;
  }
  FocusoutClientValidation(ClientName: string) {
    this.isCompanyList = false;
    if (this.clientType.find(i => i.name === ClientName)) {
      this.IsClientSelected = true;
    }
    else {
      this.IsClientSelected = false;
    }
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
  }
  btnEnable() {
    this.isShow = false;
  }

  // ValidatePriceForm(event) {
  //   this.btnDisable = false;
  //   this.formcontrol = event.target.getAttribute('formControlName')
  //   let value = event.target.value;
  //   if (value >= 10000) {
  //     event.preventDefault()
  //     this.PriceForm.controls[this.formcontrol].setValue(value.toString().substring(0, 4));
  //   }
  //   else if (value == '') {
  //     this.PriceForm.controls[this.formcontrol].setValue(0);
  //   }
  // }

  // ValidateCreditForm(event) {
  //   this.formcontrol = event.target.getAttribute('formControlName')
  //   let value = event.target.value;
  //   if (value >= 10000) {
  //     event.preventDefault();
  //     this.CreditForm.controls[this.formcontrol].setValue(9999);
  //   }
  // }

  // convertTwoDecimalPrice(event) {
  //   let _this = this;
  //   let controlName = event.target.getAttribute('formControlName');
  //   if (event.target.value == undefined || event.target.value == "") {
  //     event.target.value = 0;
  //   }
  //   let value = parseFloat(event.target.value).toFixed(2);
  //   _this.pf[controlName].patchValue(value);
  //   this.btnDisable = false;
  // }

  // keyPressNumbers(event) {
  //   let _this = this;
  //   let s = this.numberOnly(event);
  //   if (s) this.btnDisable = false;

  //   if (event.target.name == 'PartialCredit' && _this.cf.PartialCredit.value === 0) {
  //     _this.cf.PartialCredit.patchValue('');
  //   }
  //   else if (event.target.name == 'FullCredit' && _this.cf.FullCredit.value === 0) {
  //     _this.cf.FullCredit.patchValue('');
  //   }
  //   else if (event.target.name == 'BankConnectionsCredit' && _this.cf.BankConnectionsCredit.value === 0) {
  //     _this.cf.BankConnectionsCredit.patchValue('');
  //   }
  //   else if (event.target.name == 'SMSCredit' && _this.cf.SMSCredit.value === 0) {
  //     _this.cf.SMSCredit.patchValue('');
  //   }
  //   return s;
  // }

  // keyPressDecimals(event) {
  //   let _this = this;
  //   let s = this.decimalOnly(event);
  //   if (s) this.btnDisable = false;

  //   if (event.target.name == 'PartialPrice' && _this.pf.PartialPrice.value === '0') {
  //     _this.pf.PartialPrice.patchValue('');
  //   }
  //   else if (event.target.name == 'FullPrice' && _this.pf.FullPrice.value === '0') {
  //     _this.pf.FullPrice.patchValue('');
  //   }
  //   else if (event.target.name == 'BankConnectionsPrice' && _this.pf.BankConnectionsPrice.value === '0') {
  //     _this.pf.BankConnectionsPrice.patchValue('');
  //   }
  //   else if (event.target.name == 'SMSPrice' && _this.pf.SMSPrice.value === '0') {
  //     _this.pf.SMSPrice.patchValue('');
  //   }
  //   return s;
  // }

  encryptClientId(clientId: string): string {
    let encryptClientId = this._cryptoService.encrypt(clientId);
    return encryptClientId;
  }

  trackbyId(index, clientType) {
    return clientType.email;
  }

  ClickDomain() {
    window.open("https://" + this.findedData.subDomain + '.' + this.domainUrl);
  }

  CheckSubdomain() {
    if (this.f['SubDomain'].value?.length > 2) {
      this._dmsService.SubdomainExist(this.f['SubDomain'].value).subscribe((res: any) => {
        if (res != null) {
          this.SubdomainExistApiSuccess = true;
          if (res.result) {
            this.f['SubDomain'].setErrors({ 'incorrect': true });
            this.IsSubdomainExist = true;
          }
          else {
            this.IsSubdomainExist = false;
          }
        }
      })
    }
  }

  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   else {
  //     return true;
  //   }
  // }

  // decimalOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode == 46) {
  //     return true;
  //   }
  //   else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   else {
  //     return true;
  //   }
  // }

  CheckCompany(Check: string) {
    if (Check == "Company") {
      this.IsCompanyExist = this.clientType?.some(i => i.name.toLowerCase() === this.f['Name'].value.toLowerCase());
      if (this.IsCompanyExist) {
        this.f['Name'].setErrors({ 'incorrect': true });
      }
    }

    if (Check == "Number") {
      this.IsCNumberExist = this.clientType?.find(i => i.number === this.f['Number'].value);
      if (this.IsCNumberExist) {
        this.f['Number'].setErrors({ 'incorrect': true });
      }
    }
  }

  // restrictZero(event: any) {
  //   if ((this.InvalidChars.includes(event.key)) || (event.target.value.length === 0 && event.key === "0")) {
  //     event.preventDefault();
  //   }
  // }

  // onFocusOutEvent(event: any) {
  //   if (event.target.value.length === 0) {
  //     let value = event.target.value;
  //     if (this.InvalidChars.includes(value)) {
  //       event.preventDefault();
  //     }
  //     else if (value == '') {
  //       let formcontrol = event.target.getAttribute('formControlName');
  //       this.CreditForm.controls[formcontrol].setValue(0);
  //     }
  //   }
  // }
}
