import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { UserRights, userTypeEnum } from '../../../Extras/UserRights/userRights';


@Component({
  selector: 'app-invoice-client',
  templateUrl: './invoice-client.component.html',
  styleUrls: ['./invoice-client.component.css']
})
export class InvoiceClientComponent implements OnInit {
  loader: boolean = true;
  IsDataFound: boolean;
  InvoiceList: any;
  transactionList: any;
  UserTypes: string;
  superAdminBackBtn: any;
  systemAdminBackBtn: any;
  clientAdminBackBtn: any
  item: number;


  constructor(private _dmsService: DmsasService, private userrights: UserRights, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    
    this.route.queryParams.subscribe(params => {
      this.item = params['clientId'];
      if (!this.item) {
        this.item = +sessionStorage.getItem('ClientId');
      }
    });
    this.UserTypes = sessionStorage.getItem('type');
    let userTypeId = this.userrights.GetUserType();
    
    if (userTypeId == userTypeEnum.superAdmin) {
      this.superAdminBackBtn = true;
    }
    else if (userTypeId == userTypeEnum.systemAdmin) {
      this.systemAdminBackBtn = true;
    }
    else if (userTypeId == userTypeEnum.companyAdmin) {
      this.clientAdminBackBtn = true;
    }

    this.getPaymentTransaction();
  }

  getPaymentTransaction() {
    this._dmsService.getPaymentTransaction(this.item).subscribe((res: any) => {
      if (res?.statusCode != -1) {
        if (res.result != null && res.result.length > 0) {
          this.loader = false;
          this.transactionList = res.result;
        }
        else {
          this.loader = false;
          this.IsDataFound = true;
        }
      }
      else {
        this.loader = false;
        this.IsDataFound = true;
      }
      this._dmsService.ClearQueryParams();
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }  

  downloadInvoicePDF(transactionId: string) {
    let pdfDocName = `${transactionId}.pdf`;
    this._dmsService.downloadInvoicePdf(transactionId).subscribe((data) => {
      const link = document.createElement('a');
      link.href = data.result;
      link.target = "_blank";
      link.download = pdfDocName;
      link.click();
    });
  }
}
