import { Injectable } from '@angular/core';
import * as CryptoJS from '../../../node_modules/crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptographyService {

  private key: any;

  constructor() {
    this.key = CryptoJS.enc.Utf8.parse('B0198C80a28z90qA');
  }

  // encrypts the input value based on the key
  // we are using AES encryption here and crypto-js npm extension.
  // for docs please refer to https://cryptojs.gitbook.io/docs/#documentation
  encrypt(value: string): string {
    try {

      const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
      let encrypted_value = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      return encrypted_value.toString();
    } catch (e) {
      console.log('ENC Error');
      return value;
    }
  }

  decrypt(value: string): string {
    try {

      const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
      let encrypted_value = CryptoJS.AES.decrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted_value.toString();

    } catch (e) {
      console.log('ENC Error');
      return value;
    }
  }

  Decrypt_Client_Data(value: string): string {
    try {

      let iv = CryptoJS.enc.Utf8.parse('8080808080808080');
      let decrypted = CryptoJS.AES.decrypt(value, this.key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);     

    } 
    catch (e) {
      console.log('ENC Error');
      return value;
    }
  }
}
