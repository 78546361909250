import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activate-account-page',
  templateUrl: './activate-account-page.component.html',
  styleUrls: ['./activate-account-page.component.css']
})
export class ActivateAccountPageComponent implements OnInit {
  show = false;
  type = 'password';
  Islength = false;
  IsUpperLowerCase = false;
  IsNumber = false;
  img_src = '../../../../assets/images/Eye-Open.svg';
  constructor() { 
    // Do nothing
  }
  ShowHidePass() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.img_src = '../../../../assets/images/Eye-Close.svg';
    } else {
      this.type = 'password';
      this.img_src ='../../../../assets/images/Eye-Open.svg';
    }
  }
  ngOnInit(): void {
    // Do nothing
  }

}
