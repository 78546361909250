import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerDetails, EmployeeStatusModel } from 'src/app/Models/dmsModel';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { saveAs } from 'file-saver';
import { UserRights, userTypeEnum } from '../../Extras/UserRights/userRights';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;
@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.css']
})
export class SearchEmployeeComponent implements OnInit, OnDestroy {

  cusomerList: any;
  searchText = '';
  CustomerDetails = new CustomerDetails();
  UserTypes: string;
  superAdminBackBtn: any;
  systemAdminBackBtn: any;
  clientAdminBackBtn: any
  agentAdminBackBtn: any
  loader: boolean = true;
  noData: boolean = false;
  name: any;
  subscriptions: Subscription[] = [];
  companyStatus: boolean = false;
  searchType: string = 'Credit Search';
  employeeData: any;
  UserForm: FormGroup;
 selectedEmployee:any
  constructor(private _dmsService: DmsasService, private userrights: UserRights, private formBuilder: FormBuilder,private modalService: NgbModal) { }

  ngOnInit(): void {
    // if (sessionStorage.getItem('comtype') == 'P'){
    //   this.searchType = 'Asset Search';
    //   this.companyStatus = true;
    // }
    this.UserTypes = sessionStorage.getItem('type');
    let userTypeId = this.userrights.GetUserType();
    if (userTypeId == userTypeEnum.agent) {
      this.agentAdminBackBtn = true;
    }
    if (userTypeId == userTypeEnum.superAdmin) {
      this.superAdminBackBtn = true;
    }
    if (userTypeId == userTypeEnum.systemAdmin) {
      this.systemAdminBackBtn = true;
    }
    if (userTypeId == userTypeEnum.companyAdmin) {
      this.clientAdminBackBtn = true;
    }
    this.GetEmployeeList();

    this.UserForm = this.formBuilder.group({
      Title: ['', Validators.required],
      FName: ['', Validators.required],
      LName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      UserTypeId: ['', Validators.required],
      ClientId: [0],
    });
  }
 

  ngOnDestroy(): void {    
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  GetEmployeeList() {
    this.subscriptions.push(this._dmsService.GetAllEmployees().subscribe((res: any) => {
      if (res.data != null && res.data.length > 0) {
        this.loader = false;
        this.cusomerList = res.data;
        this.selectedEmployee=this.cusomerList[0];
      }
      else {
        this.loader = false;
        this.noData = true;
      }
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    }));
  }


  openmenu() {
    // this.Type = '';
    // this.IsClientSelected = true;
  }
 closeModal() {
    $('#empdelete').modal('hide');
  }
  openDeleteModal(empId: any) {
    this.selectedEmployee = this.cusomerList.filter(cc => cc.empId == empId)[0];
    $('#empdelete').modal('show');
  }
  ChangeStatus(status: number, userId: number) {
    const employeeStatus: EmployeeStatusModel = {
      EmpId: userId,
      Status: status
    };    
    this._dmsService.UpdateEmployeeStatus(employeeStatus).subscribe((res => {
      if (res!=null)
      {
        this.GetEmployeeList();
      }
      $('#empdelete').modal('hide');
    }))
  }
  trackByFn(index, item) {
    return item.EmpId;
  }
  downloadCSV() {
   let _this =this;
   let new_list = this.cusomerList.map(function(obj) {
    return {
      EmployeeName: obj.firstName+' '+obj.lastName,
      EmployeeEmail: obj.emailAddress,
      CompanyName: obj.companyName,
      CompanyNumber: obj.companyNumber,
      Experian: obj.experian,
      Bank: obj.account,
      LastLogin: obj.lastLogin,
      Status: _this.getStatusName(obj.status)
    }
  });

    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(new_list[0]);
    const csv = new_list.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'Filtered-Employees.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  open(content: any) {
    this.modalService.open(content, { windowClass: 'custom-class' });

  }
  ViewUser(editdetailsmodal: any, item: any) {
    this.employeeData = this.cusomerList.filter(cc => cc.empId == item.empId)[0];
    this.modalService.open(editdetailsmodal, { windowClass: 'custom-class' });
  }
    getStatusName(statusId:number):string {
    if(statusId==3)
    return "Pending Activation";
    else if(statusId==2)
    return "Inactive";
    else if(statusId==1)
    return "Active";
    else statusId;
  }
}
