import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserPasswordDTO } from 'src/app/Models/dmsModel';
import { CryptographyService } from 'src/app/Services/cryptography.service';
import { DmsasService } from 'src/app/Services/dmsas.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate-user-password',
  templateUrl: './activate-user-password.component.html',
  styleUrls: ['./activate-user-password.component.css']
})
export class ActivateUserPasswordComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  img_src = this.imgSrc + 'eye.svg';
  activateAccForm: FormGroup;
  IsImageLoaded: boolean = false;
  submitted = false;
  RequirmentPassword: boolean = false;
  Islength = false;
  IsUpperLowerCase = false;
  IsNumber = false;
  IsSymbol = false;
  userName: string;
  FirstName: string;
  show = false;
  type = 'password';
  loader: boolean = true;
  message: string = 'We are checking your request';

  constructor(private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _Service: DmsasService,
    private crypto_service: CryptographyService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['refid'] && params['refid'] != null) {
        let refid = params['refid'].toString().replace(/\%20/g, "+").replace(/ /g, "+");
        let decryptedRefId = this.crypto_service.Decrypt_Client_Data(refid);
        let splitted = decryptedRefId.split("~~~", 2);
        if (splitted != null && splitted.length == 2) {
          this.FirstName = splitted[0].toString();
          this.userName = splitted[1].toString();
        }
      }
    });
  }

  ngOnInit(): void {
    this.activateAccForm = this.fb.group({
      UserName: new FormControl({ value: this.userName, disabled: true }),
      SetPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)])
    });

    this.CheckUserExists(this.userName);
  }

  get f() {
    return this.activateAccForm.controls;
  }

  CheckUserExists(userName: string) {
    this._Service.CheckUserExist(userName).subscribe((res: any) => {
      if (res != null && (res.statusCode == -1 || res.statusCode == 0)) {
        this.router.navigate(["error-page"]);
      }
      this.loader = false;
    });
  }

  OnPasswordPattern(event: any): any {
    let P_pattern = event.target.value;

    if (P_pattern.search(/(?=.*?[a-z])(?=.*?[A-Z])/) != -1) {
      this.IsUpperLowerCase = true;
    } else {
      this.IsUpperLowerCase = false;
    }
    if (P_pattern.search(/\d/) != -1) {
      this.IsNumber = true;
    } else {
      this.IsNumber = false;
    }
    if (P_pattern.search(/[!@#$%^&*(),.?":{}|<>]/) != -1) {
      this.IsSymbol = true;
    } else {
      this.IsSymbol = false;
    }
    if (P_pattern.toString().length >= 8) {
      this.Islength = true;
    } else {
      this.Islength = false;
    }
  }

  ShowHidePass() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.img_src = this.imgSrc + 'eye-green.svg';
    } else {
      this.type = 'password';
      this.img_src = this.imgSrc + 'eye.svg';
    }
  }

  onSubmit() {
    this.submitted = true;
    this.IsImageLoaded = true;

    if (this.activateAccForm.invalid) {
      this.IsImageLoaded = false;
      return;
    }

    if (!this.IsUpperLowerCase || !this.IsNumber || !this.IsSymbol || !this.Islength) {
      this.RequirmentPassword = true;
      return;
    }

    const userPwdModel: UserPasswordDTO = {
      UserName: this.f.UserName.value,
      Password: this.f.SetPassword.value,
    };

    this._Service.ActivateUserAccount(userPwdModel).subscribe((res: any) => {
      if (res != null && res.result == 1) {
        this.router.navigate(['/login']);
      } else {
        console.log('Error found');
      }
    });

  }


}
