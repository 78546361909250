import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'Blossm';
  timeOutSeconds: number = 0;  
  IsTimerOpenPopup: boolean;
  subscriptions: Subscription[] = [];
  
  constructor(private bnIdle: BnNgIdleService,@Inject(DOCUMENT) private dom) 
  {  
    //"TimeoutPopup": 600, (10 minutes : 10 * 60)
    this.timeOutSeconds = environment.configUrl['TimeoutPopup']
  }    

  ngOnInit(): void {
    this.subscriptions.push(this.bnIdle.startWatching(this.timeOutSeconds).subscribe((isTimedOut: boolean) => {   
      if (isTimedOut && sessionStorage.getItem('token')) {
        console.log("startWatching: " + this.IsTimerOpenPopup);
        this.IsTimerOpenPopup = true;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    sessionStorage.clear();
  }

  TimerOpenPopupChange(event) {
    this.IsTimerOpenPopup = event;
  }

}
