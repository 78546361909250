<div class="page-wrapper ">
    <div class="footerbg">
        <div id="block-page-wrapper">
            <div id="img-wrapper">
                <img class="img-fluid width-img" src="{{imgSrc}}error-message-icon.svg" alt="image not found">
            </div>
            <div id="block-info-txt-container">
                <div id="block-title">Too many failed login attempts, <br>
                    your access has been locked temporarily
                </div>
                <div id="block-info-txt" class="padding-top-content-01">Your account has been locked, please try again
                    in 10 minutes. </div>
                <div class="new-line-height">If this wasn't you, please contact
                    <a href="mailto:support@blossm.me" id="block-info-txt-bold">
                        support@blossm.me </a>
                </div>
            </div>
        </div>
    </div>
</div>