<div class="login-page-conatiner">
    <div id="login-wrapper-logo">
    </div>
    <div class="login-features-name">Hello {{FirstName}}. Activate your account</div>
    <form [formGroup]="activateAccForm">
        <div id="login-form-container">
            <div class="form-group">
                <input type="text" class="form-control input-field" placeholder="Enter username"
                    formControlName="UserName" />
            </div>
            <div class="form-group">
                <input type="{{ type }}" class="form-control input-field" placeholder="Set Password"
                    formControlName="SetPassword" (keyup)="OnPasswordPattern($event)" minLength="8" maxlength="20"
                    [ngClass]="{'is-invalid': f.SetPassword.touched && f.SetPassword.errors }" />
                <em class="eye-icon hover-cursor" (click)="ShowHidePass()">
                    <img id="open-eye" class="img-fluid" src="{{img_src}}" alt="image not found" width="auto"
                        height="auto"></em>

                <div *ngIf="f.SetPassword.errors || RequirmentPassword" class="invalid-feedback">
                    <div *ngIf="f.SetPassword.errors?.required">
                        Password is required
                    </div>
                    <div *ngIf="f.SetPassword.errors?.minlength">
                        Password must be at least 8 characters
                    </div>
                    <div *ngIf="f.SetPassword.errors?.maxlength">
                        Password must not be more than 20 characters
                    </div>
                    <div *ngIf="RequirmentPassword" class="invalid-feedback">
                        Password should satisfy all the requirements
                    </div>
                </div>
            </div>

            <div>
                <ul class="validation-requirmet">
                    <li [ngClass]="IsUpperLowerCase ? 'green ' : 'cross'">
                        An uppercase & lowercase character
                    </li>
                    <li [ngClass]="IsNumber ? 'green ' : 'cross'">
                        Please provide at least one number
                    </li>

                    <li [ngClass]="Islength ? 'green ' : 'cross'">
                        Password must be between 8-20 characters
                    </li>
                </ul>
            </div>
            <div id="btn-sign-in-wrapper">
                <button type="button" class="btn sg-btn" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </form>
</div>